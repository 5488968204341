import {React, useState, useEffect} from 'react'
import { Grid } from '@mui/material'
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore'
import { db } from '../Firebase/Firebase';
import { Link } from 'react-router-dom';

const AdminDashboard = () => {

  const [premature, setPremature] = useState([])
  
    

  async function fetchDataFromFirestore() {
      const querySnapshot = await getDocs(collection(db, "Approved-plan"))
      const data = []
      querySnapshot.forEach((doc)=>{
        data.push({id: doc.id, ...doc.data()})
      })
      return data
    }

    useEffect(()=>{
      async function fetchData(){
        const data = await fetchDataFromFirestore()
        console.log(data)
        const prematureWithTrueIsPrematureApplied = data.filter(item => item.IsPrematureApplied === "true");
        setPremature(prematureWithTrueIsPrematureApplied);
        // console.log(data)
      }
      fetchData()
    },[])

console.log(premature)

const handleDelete = async (id)=>{
  // Implement your delete logic here
  try {
    await deleteDoc(doc(db, "Approved-plan", id));
    setPremature(premature.filter(blog => blog.id !== id));
    console.log("Deleted blog with ID:", id);
    alert("Investment Deleted")
} catch (error) {
    console.error("Error deleting blog:", error);
}
}

const handleDeleteRequest = async (id)=>{
  try {
    const docRef = doc(db, "Approved-plan", id);
    await updateDoc(docRef, {
        IsPrematureApplied:"false",
    });
    alert("You Applied for Pre-Mature withdrawal, We will revert back soon");
    // Optionally, redirect the user to the updated blog post page or any other page
} catch (error) {
    console.error(error);
    alert("Failed to update blog post. Please try again.");
}
}

  return (
    <div className='mt-10'>

<Grid container>

<Grid item md={6} sm={12}>
<div className=" m-2 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-750 dark:hover:bg-gray-700">   
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Users Details</h5>
            <p className="font-normal text-gray-700 dark:text-gray-200">Total Number of Users : <span className='ml-4 text-gray-100'>7</span> </p> 
            <p className="font-normal text-gray-700 dark:text-gray-200">Total Number of investor : <span className='ml-4 text-gray-100'>3</span> </p> 
        </div>
</Grid>

<Grid item md={6} sm={12}>
<div className=" m-2 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-750 dark:hover:bg-gray-700">   
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Investment Details</h5>
            <p className="font-normal text-gray-700 dark:text-gray-200">Total Number of Investment Received  : <span className='ml-4 text-gray-100'>7</span> </p> 
            <p className="font-normal text-gray-700 dark:text-gray-200">Total Amount Received : <span className='ml-4 text-gray-100'>Rs. 30,00,000/-</span> </p> 
        </div>
</Grid>

<Grid item md={6} sm={12}>
<div className=" m-2 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-750 dark:hover:bg-gray-700">   
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Enquiries</h5>
            <p className="font-normal text-gray-700 dark:text-gray-200">Total Number of Enquiry recevied : <span className='ml-4 text-gray-100'>7</span> </p> 
        </div>
</Grid>





<Grid item md={6} sm={12}>
<div className=" m-2 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-750 dark:hover:bg-gray-700">   
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Blogs Details </h5>
            <p className="font-normal text-gray-700 dark:text-gray-200">Number of Blogs : <span className='ml-4 text-gray-100'>7</span> </p> 
        </div>
</Grid>



</Grid>


<div className='my-8 m-2 '>
        <h1 className=' px-6 py-3 border rounded-2xl text-center text-m font-bold text-gray-400 bg-gray-50 dark:bg-gray-700 dark:text-white'>List of All users Applied for Pre-Mature Withdrawal</h1>
        </div>
       <div className="m-2 relative overflow-x-auto shadow-md sm:rounded-lg">
           
           
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            User's name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Mobile Number
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Premium
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Plan term
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Installment Paid
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Maturity Date
                        </th>
                        
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                    </tr>
                </thead>
               
               {
                premature.map((item)=>(
                    <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {item.Name}
                        </td>
                        <td className="px-6 py-4">
                        {item.PhoneNumber}
                        </td>
                        <td className="px-6 py-4">
                        {item.InvestedAmount}
                        </td>
                        <td className="px-6 py-4">
                        {item.PlanTerm}
                        </td>
                        <td className="px-6 py-4">
                        {item.NumberOfInstallmentPaid}
                        </td>
                        <td className="px-6 py-4">
                        {item.MaturityDate}
                        </td>

                        <td className="px-6 py-4">
                        <button onClick={() => handleDeleteRequest(item.id)} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Delete request</button>
                        </td>
                        <td className="px-6 py-4 text-right">
                        <Link to={`/admin/83547-245/userdata/${item.USERID}`} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">ViewUser
                            </Link>
                        </td>
                        <td className="px-6 py-4">
                        <button onClick={() => handleDelete(item.id)} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Delete Investment</button>
                        </td>      
                    </tr>
                </tbody>
                ))
               }
            </table>
        </div>
    
    </div>
  )
}

export default AdminDashboard
