import React, { useState, useRef, useEffect } from 'react';
import Navbar from './Navbar';
import Chart from 'chart.js/auto';
import Grid from '@mui/material/Grid';

const Calculator = () => {
  const [principal, setPrincipal] = useState('')
  const [time, setTime] = useState('');
  const [result, setResult] = useState('');

  const chartRef = useRef(null);

  const calculateInterest = () => {
    const parsedPrincipal = parseFloat(principal);
        const parsedTime = parseFloat(time);
    if (!parsedPrincipal || !parsedTime) {
      alert('Please fill in all fields.');
      return;
    }
    const amount = parsedPrincipal * Math.pow((1 + 12 / 100), parsedTime);
    const interest = amount - parsedPrincipal;
    setResult(`After ${time} years, your investment will be worth ₹${amount.toFixed(2)} with a total interest of ₹${interest.toFixed(2)}.`);
    updateChart(parsedPrincipal, interest);
  };

  const updateChart = (principal, interest) => {
    if (chartRef.current !== null) {
      chartRef.current.destroy();
    }

    const data = {
      labels: ['Principal', 'Interest'],
      datasets: [{
        data: [principal, interest],
        backgroundColor: ['rgb(79,70,229)', 'rgb(242,223,245)'],
      }],
    };

    const config = {
      type: 'pie',
      data: data,
    };

    const ctx = document.getElementById('chartPie').getContext('2d');
    chartRef.current = new Chart(ctx, config);
  };

  return (
    <>
      <Navbar />
      <div className="bg-gray-100 min-h-screen flex  items-center">
        <Grid container justifyContent="center" alignItems="center" direction="row">
          <Grid item md={5} xs={12}>
            <div className="max-w-md w-[90vw] bg-white p-8 m-4 rounded-lg shadow-lg border-2 border-blue-500">
              <h1 className="text-3xl font-bold text-center mb-8">FD Calculator</h1>
              <h1 className="text-xl font-bold text-center mb-8">Calculate your Maturity amount with annual 12 percent return</h1>
              <div className="mb-4">
                <label htmlFor="principal" className="block text-gray-700">Principal Amount:</label>
                <input type="number" id="principal" className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500" placeholder="Enter principal amount" value={principal} onChange={(e) => setPrincipal(e.target.value)} />
              </div>
              <div className="mb-4">
                <label htmlFor="time" className="block text-gray-700">Time Period (Years):</label>
                <input type="number" id="time" className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500" placeholder="Enter time period in years" value={time} onChange={(e) => setTime(e.target.value)} />
              </div>
              <button id="calculateBtn" className="w-full bg-blue-500 text-white rounded-md py-2 px-4 hover:bg-blue-600 focus:outline-none mb-4" onClick={calculateInterest}>Calculate</button>
            </div>
          </Grid>
          <Grid item md={6} xs={12} justifyContent="center" alignItems="center" direction="row">
            <div className="lg:w-[50vw] lg:h-[50vh] flex flex-col">
              <canvas id="chartPie" ></canvas>
              <div id="result" className="text-lg m-2 font-semibold text-center ">{result}</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Calculator;
