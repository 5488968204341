import React from 'react'
import Homepage from './Homepage'
import Featuredpage from './Featuredpage'
import Billingsection from './Billingsection'
import TestimonialsSection from './TestimonialsSection'
import BlogCards from './BlogCards'
import Footer from './Footer'

const MainHomePage = () => {
  return (
    <div>
      <Homepage/>
      <Featuredpage/>
      <Billingsection/>
      <TestimonialsSection/>
      <BlogCards/>
      <Footer/>
    </div>
  )
}

export default MainHomePage
