import {React, useState, useEffect} from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore'
import { db } from '../Firebase/Firebase';
import { Link } from 'react-router-dom';

const ManageBlogPost = () => {
    const [blogData, setBlogData] = useState([])


    async function fetchDataFromFirestore() {
        const querySnapshot = await getDocs(collection(db, "Blogs"))
        const data = []
        querySnapshot.forEach((doc)=>{
          data.push({id: doc.id, ...doc.data()})
        })
        return data
      }

      useEffect(()=>{
        async function fetchData(){
          const data = await fetchDataFromFirestore()
          
          setBlogData(data);
          console.log(data)
          
        }
        fetchData()
      },[])


      const handleDelete = async (id) => {
        // Implement your delete logic here
        try {
            await deleteDoc(doc(db, "Blogs", id));
            setBlogData(blogData.filter(blog => blog.id !== id));
            console.log("Deleted blog with ID:", id);
            alert("Blog Deleted")
        } catch (error) {
            console.error("Error deleting blog:", error);
        }
    };
    return (
        <div className="m-2 my-5 relative overflow-x-auto shadow-md sm:rounded-lg">
           
           
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Blog Heading
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Edit
                        </th>
                        <th scope="col" className="px-6 py-3">
                           Delete
                        </th>
                    </tr>
                </thead>
               
               {
                blogData.map((item)=>(
                    <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-700 dark:hover:bg-gray-700">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {item.Heading}
                        </td>
                        <td className="text-center px-6 py-4">
                            <Link to={`/admin/83547-245/updateBlog/${item.id}`} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Edit
                            </Link>
                        </td>
                        <td className="text-center px-6 py-4">
                        <button onClick={() => handleDelete(item.id)} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Delete</button>
                        </td>
                    </tr>
                  
                </tbody>
                ))
               }
               
                
            </table>
        </div>
    );
};

export default ManageBlogPost;
