import {React, useState} from 'react';
import {useUserContext} from "../Context/authContext/index"
import { useNavigate } from 'react-router-dom';



const ForgotPassword = () => {
  
  const [email, setEmail] = useState('')
  const {forgotPassword} = useUserContext()
  const Navigate = useNavigate()

  const Login =  ()=>{
    forgotPassword(email)
    alert("Link sent to your Email please check and resent password")
    Navigate("/login")
  }


  return (
    <>

    <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center">

      <div className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1">
        <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
         
          <div className="mt-12 flex flex-col items-center">
            <h1 className="text-2xl xl:text-3xl font-extrabold">
              Sign up
            </h1>
            <div className="w-full flex-1 mt-8">
              <div className="flex flex-col items-center">

              </div>

              <div className="my-12 border-b text-center">
                <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
 Login with e-mail
                </div>
              </div>

              <div className="mx-auto max-w-xs">
              
                <input required onChange={(e) => setEmail(e.target.value)} className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white" type="email" placeholder="Email" />
                
                <button onClick={Login} className="mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none">
                  Send Reset Email
                </button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default ForgotPassword;
