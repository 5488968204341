import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Compounding',
    description:
      'Compounding is the best way to grow your money. It is the process of earning interest on both the principle and the accumulated interest over time.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Flexibility',
    description:
      'We offer you flexibility in investment plans as well as in your maturity benefits',
    icon: LockClosedIcon,
  },
  {
    name: 'Safety',
    description:
      'Investement in market is risky but we are providing fixed income plan which will fixed your maturity amount at the time of investment irrespective to the fluctuation in market',
    icon: ArrowPathIcon,
  },
  {
    name: 'Customization',
    description:
      'In our investment plan, where your financial journey in your hands. we provide you the  customizaton that you can invest in yearly plans but receive interest monthly, quarterly, half yearly as well as yearly. ',
    icon: FingerPrintIcon,
  },
]

export default function Featuredpage() {
  return (
    <div className="py-10 sm:py-10 bg-gray-100 border rounded-3xl m-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
           <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Everything you need to know before investing
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Money in your bank account will always be depreciate its value, investing and grow your money with us.
          </p>
        </div>
        <div className="mx-auto  max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
