import {React, useState } from 'react'
import {  doc, updateDoc } from "firebase/firestore";
import { db } from '../Firebase/Firebase';
import { useNavigate, useParams } from 'react-router-dom';


const UpdateUsersPayment = () => {

    const [noOfInstallment, setNoOfInstallment] = useState('')
    const [loading, setLoading] = useState(false)
    const params = useParams();
    const paymentID = params.id;
    const Navigate = useNavigate()


    const handlefinalStep = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const docRef = doc(db, "Approved-plan", paymentID);
            await updateDoc(docRef, {
                
                
                NumberOfInstallmentPaid:noOfInstallment,
                // USERID: UserID
                // You may need to update the image handling logic here
            });
            alert("Blog Post Updated Successfully");
            Navigate("/admin")
            // Optionally, redirect the user to the updated blog post page or any other page
        } catch (error) {
            console.error("Error updating blog post:", error);
            alert("Failed to update blog post. Please try again.");
        }
    };



  return (
    <div>
       <div>
    <div className="isolate  bg-white px-6 py-10 sm:py-32 lg:px-8">
      

      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Select your Investment plan</h2>
      </div>
      
      <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          
              
              <div>
                <label htmlFor="yearly-duration" className="block text-sm font-semibold leading-6 text-gray-900">
                  Number of Installment Paid
                </label>
                <div className="mt-2.5">
                <input
                    type="text"
                    required
                    onChange={(e) => setNoOfInstallment(e.target.value)} 
                    placeholder="Example: 3"
                    name="investment-amount"
                    id="yearly-investment-amount"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>


        <div className="mt-10 flex">
          <button
            type="submit"
            disabled = {loading? true:false}
            className="block w-full m-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handlefinalStep}
          >
            Approve Payment
          </button>
        </div>
        </div>    
      </form>
    </div>
    
                    </div>
    </div>
  )
}

export default UpdateUsersPayment
