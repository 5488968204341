import React from 'react';
import './style.css'; // Import your CSS file
import bg from "./bg.jpg"
import Navbar from '../Navbar';
import Footer from '../Footer';


const AboutUs = () => {
    return (

        <>
 <header className='absolute inset-x-0 top-0 z-50'>
<Navbar/>
</header>
        <div>
            <section className="about section bd-container" id="about">

    <div className="absolute inset-x-0 top-[-10rem] sm:top-[-20rem] transform-gpu overflow-hidden blur-3xl">
  <div
    className="relative left-1/2 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
    style={{
      clipPath:
        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    }}
  />
</div>

            <h2 className="mt-12 section-title">About Us</h2>
            <div className="about__container bd-grid">
                <div className="about__data bd-grid">
                    <p className="about__description">
                        <span>Hello, We are <br/></span>
                        Sharma Asset Management is a leading investment firm dedicated to helping clients achieve their financial goals through strategic investment solutions. With a commitment to excellence, integrity, and innovation, we provide tailored investment strategies and personalized service to individuals, families, and institutions.
                    </p>
                    <div>
                        <span className="about__number">05</span>
                        <span className="about__achievement">Years of Trust</span>
                    </div>
                    <div>
                        <span className="about__number">1.2 cr+</span>
                        <span className="about__achievement">Investment Gained</span>
                    </div>
                    <div>
                        <span className="about__number">10K</span>
                        <span className="about__achievement">Registered User</span>
                    </div>
                </div>
                <img src={bg} alt="" className="about__img" />
            </div>  
            </section>
        </div>

        <div className='z-50'>
            <Footer/>   
        </div>     
        </>
    );
}

export default AboutUs;
