import {React, useState} from 'react'
import Navbar from './Navbar'
import Footer from "./Footer"
import { Link } from 'react-router-dom';


    function AccordionItem({ id, title, content }) {
        const [isOpen, setIsOpen] = useState(false);
      
        const toggleAccordion = () => {
          setIsOpen(!isOpen);
        };
      
        return (
       
          <div>
            <h2 id={id}>
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
                onClick={toggleAccordion}
                aria-expanded={isOpen ? "true" : "false"}
                aria-controls={`${id}-body`}
              >
                <span className="flex font-bold  items-center">{title}</span>
                <svg
                  data-accordion-icon
                  className={`w-3 h-3 rotate-${isOpen ? '0' : '180'} shrink-0`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div
              id={`${id}-body`}
              className={`${
                isOpen ? 'block mb-5' : 'hidden'
              } p-5 border mb-10 border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900`}
              aria-labelledby={id}
            >
              {content}
            </div>
          </div>
          
        );
      }

      function InvestmentPlans() {
        return (

<>

<Navbar/>

            <div className='mx-5 my-10 text-center bg-gray-700 p-5 rounded-3xl '>
                <h1 className='text-3xl font-bold text-gray-100 '>
                    Our Investment PLans for You
                </h1>

            </div>

            <div className='mx-20 my-10 text-center bg-gray-700 p-5 rounded-3xl '>
                <h1 className='text-m font-bold text-gray-100 '>
                    Yearly plan
                </h1>

            </div>

          <div id="accordion-open" data-accordion="open">
            <AccordionItem
              id="accordion-open-heading-1"
              title="SAM 5 Years Plan"
              content={
                <div>
                 <div className='mt-10 relative overflow-x-auto shadow-md sm:rounded-lg  mb-10'>
                 <table className="mt-5  w-full text-sm text-left bg-gray-700 rtl:text-right text-gray-500 ">
                     <thead className="text-xs text-gray-100 uppercase bg-gray-700 ">
                         <tr>
                             <th scope="col" className="px-6 py-3">
                                 Investment Plan's Name
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Minimum Investment value 
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Investment Term
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Premium Years
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 waiting Years
                             </th>
                             <th scope="col" className="px-6 py-3">
                                  Yearly Return % 
                             </th> 
                             <th scope="col" className="px-6 py-3">
                                  Maturity Return 
                             </th> 
                             <th scope="col" className="px-6 py-3">
                                  Action 
                             </th> 
                         </tr>
                     </thead>
                    
                         <tbody>
                         <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                             <td className="px-6 py-4">
                                  SAM 05 Years Plan
                           </td>
                             <td className="px-6 py-4">
                              Rs. 10,000/- per Year
                             </td>
                             <td className="px-6 py-4">
                              5 Years
                             </td>  
                             <td className="px-6 py-4">
                              5 Years
                             </td>
                             <td className="px-6 py-4">
                             0
                             </td>  
                             <td className="px-6 py-4 ">  
                             30 % of annual Premium
                             </td>
                             <td className="px-6 py-4 ">  
                             Full Premium of 05 years                       
                             </td>
                             <td className="px-6 py-4 ">  
                             <Link to={"/checkout"} className="py-2.5 m-1.5 px-5 bg-indigo-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700">Buy</Link>                     
                             </td>
                         </tr>
      
                         <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                             <td className="px-6 py-4">
                              Example                     
                              </td>
                             <td className="px-6 py-4">
                              Rs. 1,00,000/- per Year
                             </td>
                             <td className="px-6 py-4">
                              5 Years
                             </td>  
                             <td className="px-6 py-4">
                              5 Years
                             </td>  
                             <td className="px-6 py-4">
                              0
                              </td>  
                             <td className="px-6 py-4 wrap-text">  
                             Rs. 30,000/- per year of plan's term
                             </td>
                             <td className="px-6 py-4 ">  
                             Rs. 5,00,000/-
                             </td>
                             <td className="px-6 py-4 ">  
                             <Link to={"/checkout"} className="py-2.5 m-1.5 px-5 bg-indigo-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700">Buy</Link>                     
                             </td>
                         </tr>
      
                     </tbody>
                 </table>
                 </div>  
                </div>
              }
            />
            <AccordionItem
              id="accordion-open-heading-2"
              title="SAM 6 Years Plan"
              content={
                <div>
               <div className='mt-10 relative overflow-x-auto shadow-md sm:rounded-lg '>
                 <table className="mt-5 w-full text-sm text-left bg-gray-700 rtl:text-right text-gray-500 ">
                     <thead className="text-xs text-gray-100 uppercase bg-gray-700 ">
                         <tr>
                             <th scope="col" className="px-6 py-3">
                                 Investment Plan's Name
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Minimum Investment value 
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Investment Term
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Premium Years
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 waiting Years
                             </th>
                             <th scope="col" className="px-6 py-3">
                                  Yearly Return % 
                             </th> 
                             <th scope="col" className="px-6 py-3">
                                  Maturity Return 
                             </th> 
                             <th scope="col" className="px-6 py-3">
                                  Action 
                             </th> 
                         </tr>
                     </thead>
                    
                         <tbody>
                         <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                             <td className="px-6 py-4">
                                  SAM 06 Years Plan
                           </td>
                             <td className="px-6 py-4">
                              Rs. 10,000/- per Year
                             </td>
                             <td className="px-6 py-4">
                             6 Years
                             </td>  
                             <td className="px-6 py-4">
                              4 Years
                             </td>
                             <td className="px-6 py-4">
                             2 Years
                             </td>  
                             <td className="px-6 py-4 ">  
                             30 % of annual Premium
                             </td>
                             <td className="px-6 py-4 wrap-text ">  
                             Full Premium of 04 years 
                             </td>
                             <td className="px-6 py-4 ">  
                             <Link to={"/checkout"} className="py-2.5 m-1.5 px-5 bg-indigo-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700">Buy</Link>                     
                             </td>
                         </tr>
      
                         <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                             <td className="px-6 py-4">
                              Example                     
                              </td>
                             <td className="px-6 py-4">
                              Rs. 1,00,000/- per Year
                             </td>
                             <td className="px-6 py-4">
                              6 Years
                             </td>  
                             <td className="px-6 py-4">
                              4 Years
                             </td>  
                             <td className="px-6 py-4">
                              2 Years
                              </td>  
                             <td className="px-6 py-4 wrap-text">  
                             Rs. 30,000/- per year for 06 years
                             </td>
                             <td className="px-6 py-4 ">  
                             Rs. 4,00,000/-
                             </td>
                             <td className="px-6 py-4 ">  
                             <Link to={"/checkout"} className="py-2.5 m-1.5 px-5 bg-indigo-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700">Buy</Link>                     
                             </td>
                         </tr>
      
                     </tbody>
                 </table>
                 </div>  
                </div>
              }
            />
            <AccordionItem
              id="accordion-open-heading-3"
              title="SAM 10 Years Plan"
              content={
                <div>
                  <div className='mt-10 relative overflow-x-auto shadow-md sm:rounded-lg '>
                 <table className="mt-5 w-full text-sm text-left bg-gray-700 rtl:text-right text-gray-500 ">
                     <thead className="text-xs text-gray-100 uppercase bg-gray-700 ">
                         <tr>
                             <th scope="col" className="px-6 py-3">
                                 Investment Plan's Name
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Minimum Investment value 
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Investment Term
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Premium Years
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 waiting Years
                             </th>
                             <th scope="col" className="px-6 py-3">
                                  Yearly Return % 
                             </th> 
                             <th scope="col" className="px-6 py-3">
                                  Maturity Return 
                             </th> 
                             <th scope="col" className="px-6 py-3">
                                  Action 
                             </th> 
                         </tr>
                     </thead>
                    
                         <tbody>
                         <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                             <td className="px-6 py-4">
                                  SAM 10 Years Plan
                           </td>
                             <td className="px-6 py-4">
                              Rs. 10,000/- per Year
                             </td>
                             <td className="px-6 py-4">
                             10 Years
                             </td>  
                             <td className="px-6 py-4">
                              6 Years
                             </td>
                             <td className="px-6 py-4">
                             4 Years
                             </td>  
                             <td className="px-6 py-4 ">  
                             30 % of annual Premium
                             </td>
                             <td className="px-6 py-4 wrap-text ">  
                             Full Premium of 6 years with extra benefits                       
                             </td>
                             <td className="px-6 py-4 ">  
                             <Link to={"/checkout"} className="py-2.5 m-1.5 px-5 bg-indigo-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700">Buy</Link>                     
                             </td>
                         </tr>
      
                         <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                             <td className="px-6 py-4">
                              Example                     
                              </td>
                             <td className="px-6 py-4">
                              Rs. 1,00,000/- per Year
                             </td>
                             <td className="px-6 py-4">
                              10 Years
                             </td>  
                             <td className="px-6 py-4">
                              6 Years
                             </td>  
                             <td className="px-6 py-4">
                              4 Years
                              </td>  
                             <td className="px-6 py-4 wrap-text">  
                             Rs. 30,000/- per year for 06 years
                             </td>
                             <td className="px-6 py-4 ">  
                             Rs. 6,00,000/-
                             </td>
                             <td className="px-6 py-4 ">  
                             <Link to={"/checkout"} className="py-2.5 m-1.5 px-5 bg-indigo-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700">Buy</Link>                     
                             </td>
                         </tr>
      
                     </tbody>
                 </table>
                 </div> 
                </div>
              }
            />
      
      <AccordionItem
              id="accordion-open-heading-4"
              title="SAM 15 Years Plan"
              content={
                <div>
                  <div className='mt-10 relative overflow-x-auto shadow-md sm:rounded-lg '>
                 <table className="mt-5 w-full text-sm text-left bg-gray-700 rtl:text-right text-gray-500 ">
                     <thead className="text-xs text-gray-100 uppercase bg-gray-700 ">
                         <tr>
                             <th scope="col" className="px-6 py-3">
                                 Investment Plan's Name
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Minimum Investment value 
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Investment Term
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Premium Years
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 waiting Years
                             </th>
                             <th scope="col" className="px-6 py-3">
                                  Yearly Return % 
                             </th> 
                             <th scope="col" className="px-6 py-3">
                                  Maturity Return 
                             </th> 
                             <th scope="col" className="px-6 py-3">
                                  Action 
                             </th> 
                         </tr>
                     </thead>
                    
                         <tbody>
                         <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                             <td className="px-6 py-4">
                                  SAM 15 Years Plan
                           </td>
                             <td className="px-6 py-4">
                              Rs. 10,000/- per Year
                             </td>
                             <td className="px-6 py-4">
                             15 Years
                             </td>  
                             <td className="px-6 py-4">
                              7 Years
                             </td>
                             <td className="px-6 py-4">
                             8 Years
                             </td>  
                             <td className="px-6 py-4 ">  
                             30 % of annual Premium
                             </td>
                             <td className="px-6 py-4 wrap-text ">  
                             Full Premium of 7 years with extra benefits                       
                             </td>
                             <td className="px-6 py-4 ">  
                             <Link to={"/checkout"} className="py-2.5 m-1.5 px-5 bg-indigo-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700">Buy</Link>                     
                             </td>
                         </tr>
      
                         <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                             <td className="px-6 py-4">
                              Example                     
                              </td>
                             <td className="px-6 py-4">
                              Rs. 1,00,000/- per Year
                             </td>
                             <td className="px-6 py-4">
                              15 Years
                             </td>  
                             <td className="px-6 py-4">
                              7 Years
                             </td>  
                             <td className="px-6 py-4">
                              8 Years
                              </td>  
                             <td className="px-6 py-4 wrap-text">  
                             Rs. 30,000/- per year for 06 years
                             </td>
                             <td className="px-6 py-4 ">  
                             Rs. 7,00,000/-
                             </td>
                             <td className="px-6 py-4 ">  
                             <Link to={"/checkout"} className="py-2.5 m-1.5 px-5 bg-indigo-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700">Buy</Link>                     
                             </td>
                         </tr>
      
                     </tbody>
                 </table>
                 </div> 
                </div>
              }
            />
      
      
      <AccordionItem
              id="accordion-open-heading-5"
              title="SAM 20 Years Plan"
              content={
                <div>
                  <div className='mt-10 relative overflow-x-auto shadow-md sm:rounded-lg '>
                 <table className="mt-5 w-full text-sm text-left bg-gray-700 rtl:text-right text-gray-500 ">
                     <thead className="text-xs text-gray-100 uppercase bg-gray-700 ">
                         <tr>
                             <th scope="col" className="px-6 py-3">
                                 Investment Plan's Name
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Minimum Investment value 
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Investment Term
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 Premium Years
                             </th>
                             <th scope="col" className="px-6 py-3">
                                 waiting Years
                             </th>
                             <th scope="col" className="px-6 py-3">
                                  Yearly Return % 
                             </th> 
                             <th scope="col" className="px-6 py-3">
                                  Maturity Return 
                             </th> 
                             <th scope="col" className="px-6 py-3">
                                  Action 
                             </th> 
                         </tr>
                     </thead>
                    
                         <tbody>
                         <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                             <td className="px-6 py-4">
                                  SAM 20 Years Plan
                           </td>
                             <td className="px-6 py-4">
                              Rs. 10,000/- per Year
                             </td>
                             <td className="px-6 py-4">
                             20 Years
                             </td>  
                             <td className="px-6 py-4">
                              12 Years
                             </td>
                             <td className="px-6 py-4">
                             8 Years
                             </td>  
                             <td className="px-6 py-4 ">  
                             30 % of annual Premium
                             </td>
                             <td className="px-6 py-4 wrap-text ">  
                             Full Premium of 04 years with extra benefits                       
                             </td>
                             <td className="px-6 py-4 ">  
                             <Link to={"/checkout"} className="py-2.5 m-1.5 px-5 bg-indigo-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700">Buy</Link>                     
                             </td>
                         </tr>
      
                         <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                             <td className="px-6 py-4">
                              Example                     
                              </td>
                             <td className="px-6 py-4">
                              Rs. 1,00,000/- per Year
                             </td>
                             <td className="px-6 py-4">
                              20 Years
                             </td>  
                             <td className="px-6 py-4">
                              12 Years
                             </td>  
                             <td className="px-6 py-4">
                              8 Years
                              </td>  
                             <td className="px-6 py-4 wrap-text">  
                             Rs. 30,000/- per year for 06 years
                             </td>
                             <td className="px-6 py-4 ">  
                             Rs. 15,00,000/-
                             </td>
                             <td className="px-6 py-4 ">  
                             <Link to={"/checkout"} className="py-2.5 m-1.5 px-5 bg-indigo-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700">Buy</Link>                     
                             </td>
                         </tr>
      
                     </tbody>
                 </table>
                 </div> 
                </div>
              }
            />
          </div>

              <div className='mb-10'>
              <div className='mx-20 my-10 text-center bg-gray-700 p-5 rounded-3xl '>
                <h1 className='text-m font-bold text-gray-100 '>
                    FD Investment Plan
                </h1>

            </div>

            <div className='mx-20 my-10 text-center  p-5 rounded-3xl '>
                <h1 className='text-m font-bold text-gray-700 '>
                  Coming Soon ...
                </h1>

            </div>
              </div>
          <Footer/>
          </>

        );
      }
      
      export default InvestmentPlans;
