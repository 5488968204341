import React from 'react';
import { Link } from 'react-router-dom';

const PlanCard = ({ title, investment, features }) => {
  return (
    <div className="flex flex-col mx-auto max-w-sm text-gray-900 rounded-2xl bg-gray-50 p-6 xl:py-9 xl:px-12 transition-all duration-500 hover:bg-gray-100">
      <h3 className="font-manrope text-2xl text-center font-bold mb-3">{title}</h3>
      <h3 className="font-manrope text-xl text-center mb-3">Minimum Investment of</h3>
      <div className="flex items-center mb-6">
        <span className="font-manrope mr-2 text-3xl font-semibold"> Rs. {investment}</span>
        <span className="text-xl text-gray-500">/ Year</span>
      </div>
      <ul className="mb-12 space-y-6 text-left text-lg text-gray-500">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center space-x-4">
            <svg className="flex-shrink-0 w-6 h-6 text-indigo-600" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <Link to={"/checkout"} className="py-2.5 m-1.5 px-5 bg-indigo-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700">Purchase Plan</Link>
      <Link to={"/calculators"} className="py-2.5 m-1.5 px-5 bg-indigo-600 shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto hover:bg-indigo-700">Calculate your return</Link>
    </div>
  );
};

export default PlanCard;
