import {React, useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { collection, getDocs, addDoc, doc, deleteDoc } from 'firebase/firestore'
import { db } from '../Firebase/Firebase'
import { Link } from 'react-router-dom'


const UserData = () => {
    const params = useParams()

    const UserID= params.id
    console.log(UserID)

    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [activeComponent, setActiveComponent] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [investmentAmount, setInvestmentAmount] = useState('');
    const [years, setYears] = useState('');
    const [maturiyAmount, setMatuarityAmount] = useState('');
    const [maturityDate, setMaturitDate] = useState('');
    const [payoutFrequency, setPayoutFrequency] = useState('');
    const [UPIID, setUPIID] = useState('');
    const [NumberOfInstallmentPaid, setNoOfInstallment] = useState('')

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const closeSidebar = () => {
        setIsOpen(false);
    };

    const handleComponentChange = (componentName) => {
        setActiveComponent(componentName);
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
      };

      const finalStep = async (e)=>{
        e.preventDefault();
        setLoading(true)
        try {
            const docRef = await addDoc(collection(db, "Approved-plan"), {
                InvestmentPlan:selectedOption,
                InvestedAmount:investmentAmount,
                PlanTerm:years,
                PayoutFrequencyOfUser:payoutFrequency,
                UserUpiID:UPIID,
                MaturityAmount:maturiyAmount,
                MaturityDate:maturityDate,
                NumberOfInstallmentPaid:NumberOfInstallmentPaid,
                USERID: UserID,
                IsPrematureApplied:"false"
            });
            console.log(docRef)
            alert("Payment Approved")
            setLoading(false)
        } catch (error) {
            console.error("Error adding document: ", error);
        }


        
  
      }

      const handleDelete = async (id) =>{
        await deleteDoc(doc(db, "investment-plan", id));
        console.log("deleted:",id)
      }

      const handleupdate = (id)=>{

      }

    const ApprovePayment = ()=>{
        renderMyComponent()
    }
    const renderMyComponent = ()=>{
        return(
        <div>
    <div className="isolate  bg-white px-6 py-10 sm:py-32 lg:px-8">

      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Select your Investment plan</h2>
      </div>
      
      <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          

        

          
            <>
              <div>
                <label htmlFor="yearly-investment-amount" className="block text-sm font-semibold leading-6 text-gray-900">
                  Enter  Yearly investmented Amount
                </label>
                <div className="mt-2.5">
                  <input
                    type="number"
                    required
                    onChange={(e) => setInvestmentAmount(e.target.value)} 
                    placeholder="Example: 1,00,000/-"
                    name="investment-amount"
                    id="yearly-investment-amount"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="yearly-duration" className="block text-sm font-semibold leading-6 text-gray-900">
                  Investement Term
                </label>
                <div className="mt-2.5">
                  <select
                    id="yearly-duration"
                    name="yearly-duration"
                    required
                    onChange={(e) => setYears(e.target.value)} 
                    className="w-full rounded-md border-100 bg-transparent bg-none py-2 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                  >
                    <option defaultValue={""}>Select Years</option>
                    <option>5 Years</option>
                    <option>6 Years</option>
                    <option>10 Years</option>
                    <option>20 Years</option>
                    <option>25 Years</option>
                  </select>
                </div>
              </div>

              <div className="sm:col-span-2">
            <label htmlFor="yearly-interest-payout" className="block text-sm font-semibold leading-6 text-gray-900">
              Choose yearly interest payout
            </label>
            <div className="relative mt-2.5">
              <select
                id="yearly-interest-payout"
                name="yearly-interest-payout"
                required
                onChange={(e) => setPayoutFrequency(e.target.value)} 
                className="w-full rounded-md border-100 bg-transparent bg-none py-2 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
              >
                <option defaultValue={""}>Select Payout Frequency</option>
                <option>Yearly Payout</option>
                <option>Half Yearly Payout</option>
                <option>Quarterly Payout</option>
                <option>Monthly Payout</option>
              </select>
            </div>
          </div>
            </>

<div>
                <label htmlFor="yearly-investment-amount" className="block text-sm font-semibold leading-6 text-gray-900">
                  Enter Maturity Amount
                </label>
                <div className="mt-2.5">
                  <input
                    type="number"
                    required
                    onChange={(e) => setMatuarityAmount(e.target.value)} 
                    placeholder="Example: 1,00,000/-"
                    name="investment-amount"
                    id="yearly-investment-amount"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="yearly-duration" className="block text-sm font-semibold leading-6 text-gray-900">
                  Maturity Date
                </label>
                <div className="mt-2.5">
                <input
                    type="date"
                    required
                    onChange={(e) => setMaturitDate(e.target.value)} 
                    placeholder="Example: 1,00,000/-"
                    name="investment-amount"
                    id="yearly-investment-amount"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="yearly-duration" className="block text-sm font-semibold leading-6 text-gray-900">
                  Number of Installment Paid
                </label>
                <div className="mt-2.5">
                <input
                    type="text"
                    required
                    onChange={(e) => setNoOfInstallment(e.target.value)} 
                    placeholder="Example: 3"
                    name="investment-amount"
                    id="yearly-investment-amount"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

          <div>
            <label htmlFor="upi-id" className="block text-sm font-semibold leading-6 text-gray-900">
              Clients UPI ID to receive your payouts
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                required
                onChange={(e) => setUPIID(e.target.value)} 
                placeholder="Example: yourname@upi"
                name="upi-id"
                id="upi-id"
                className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>

        <div className="mt-10 flex">
          <button
            type="submit"
            disabled = {loading? true:false}
            className="block w-full m-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={finalStep}
          >
            Approve Payment
          </button>
        </div>
      </form>
    </div>
                    </div>)
    }




    const [userInfo, setUserInfo] = useState([])
    const [investentPlan, setInvestmentplan] = useState([])
    const [approvedPlan, setApprovedPaln] = useState([])
    

    async function fetchDataFromFirestore() {
        const querySnapshot = await getDocs(collection(db, "user-info"))
        const data = []
        querySnapshot.forEach((doc)=>{
          data.push({id: doc.id, ...doc.data()})
        })
        return data
      }

      async function fetchDataFromFirestore2() {
        const querySnapshot = await getDocs(collection(db, "Approved-plan"))
        const data = []
        querySnapshot.forEach((doc)=>{
          data.push({id: doc.id, ...doc.data()})
        })
        return data
      }

      useEffect(()=>{
        async function fetchData(){
          const data = await fetchDataFromFirestore2()
          const userData = data.filter(item => item.USERID === UserID);
          setApprovedPaln(userData);
          console.log(userData)
        }
        fetchData()
      },[])

      async function fetchDataFromFirestore1() {
        const querySnapshot = await getDocs(collection(db, "investment-plan"))
        const data = []
        querySnapshot.forEach((doc)=>{
          data.push({id: doc.id, ...doc.data()})
        })
        return data
      }

      useEffect(()=>{
        async function fetchData(){
          const data = await fetchDataFromFirestore1()
          const userData = data.filter(item => item.USERID === UserID);
          setInvestmentplan(userData);
          console.log(userData)
        }
        fetchData()
      },[])

      useEffect(()=>{
        async function fetchData(){
          const data = await fetchDataFromFirestore()
          const userData = data.filter(item => item.USERID === UserID);
          setUserInfo(userData);
          console.log(userData)
        }
        fetchData()
      },[])
  
  
    return (
       <>
       <>
            <button
                onClick={toggleSidebar}
                type="button"
                className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
                <span className="sr-only">{isOpen ? 'Close sidebar' : 'Open sidebar'}</span>
                <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    {isOpen ? (
                        <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M4.75 2A.75.75 0 014 2.75v14.5a.75.75 0 011.5 0V2.75A.75.75 0 014.75 2zm10.5 0a.75.75 0 00-.75.75v14.5a.75.75 0 001.5 0V2.75a.75.75 0 00-.75-.75zM10 2a.75.75 0 00-.75.75v14.5a.75.75 0 001.5 0V2.75A.75.75 0 0010 2z"
                        ></path>
                    ) : (
                        <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5h-14.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                        ></path>
                    )}
                </svg>
            </button>
            <aside
                id="sidebar-multi-level-sidebar"
                className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
                    isOpen ? '' : '-translate-x-full sm:translate-x-0'
                }`}
                aria-label="Sidebar"
            >
                <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
                    <button
                        onClick={closeSidebar}
                        type="button"
                        className="absolute top-0 right-0 m-4 text-gray-500 sm:hidden"
                    >
                        <span className="sr-only">Close sidebar</span>
                        <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.178 5.86a.75.75 0 01.885 1.21l-7.071 8a.75.75 0 11-1.167-.943l7.071-8a.75.75 0 01.282-.267z"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.107 5.86a.75.75 0 10-1.167.943l7.071 8a.75.75 0 001.167-.943l-7.071-8a.75.75 0 00-.282-.267z"
                            />
                        </svg>
                    </button>
                    <ul className="space-y-2 font-medium">
                        <li>
                            <Link
                            to={"/admin/83547-245"}
                                href="/admin"
                                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            >
                                <svg
                                    className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 22 21"
                                >
                                    <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                                    <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                                </svg>
                                <span className="ms-3">Dashboard</span>
                            </Link>
                        </li>
                        <li>
            <Link onClick={() => handleComponentChange('Users')} to={"/admin/83547-245"} href="/admin" class="flex items-center p-2 mt-5 pt-20 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                  <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
               </svg>
               <span class="flex-1 ms-3 whitespace-nowrap">Users</span>
            </Link>
         </li>

         <li>
            <Link onClick={() => handleComponentChange('CreateBlog')} to={'/admin/83547-245'} href='/admin' class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                  <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
               </svg>
               <span class="flex-1 ms-3 whitespace-nowrap">Create Blogs</span>
            </Link>
         </li>

         <li>
            <Link onClick={() => handleComponentChange('ManageBlogPost')}  to={"/admin/83547-245"} href="/admin" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                  <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
               </svg>
               <span class="flex-1 ms-3 whitespace-nowrap">Manage Blogs</span>
            </Link>
         </li>
                    </ul>
                </div>
            </aside>
            <div className="p-4 sm:ml-64">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                    <h1 className='text-center text-3xl font-bold'>Hello Admin</h1>
                </div>

                <div className='Main-content-container-div'>
                <div>
        <div className='my-8 m-2 '>
        <h1 className=' px-6 py-3 border rounded-2xl text-center text-m font-bold text-gray-400 bg-gray-50 dark:bg-gray-700 dark:text-white'>User Information</h1>
        </div>
       
       
       <div className="m-2 relative overflow-x-auto shadow-md sm:rounded-lg">
           
           <h1 className=' px-6 py-2 mb-6 border rounded-2xl text-center text-sm font-bold text-gray-400 bg-gray-50 dark:bg-gray-700 dark:text-white'> User's Personal Information </h1>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Particulars 
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Value
                        </th>
                    </tr>
                </thead>
               
               {
                userInfo.map((item)=>(
                    <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            First Name 
                        </td>
                        <td className="px-6 py-4">
                        {item.Firstname}
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Last Name 
                        </td>
                        <td className="px-6 py-4">
                        {item.Lastname}
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Phone Number
                        </td>
                        <td className="px-6 py-4">
                        {item.Phone}
                        </td>
                    </tr>


                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Email 
                        </td>
                        <td className="px-6 py-4">
                        {item.Email}
                        </td>
                    </tr>


                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Aadhar Card Number
                        </td>
                        <td className="px-6 py-4">
                        {item.AadharNumber}
                        </td>
                    </tr>


                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Aadhar Vertual ID
                        </td>
                        <td className="px-6 py-4">
                        {item.AadharVirtualNumber}
                        </td>
                    </tr>

                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                           Pan Card Number
                        </td>
                        <td className="px-6 py-4">
                        {item.PanNumber}
                        </td>
                    </tr>

                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Address Line 1 
                        </td>
                        <td className="px-6 py-4">
                        {item.Address1}
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Address Line 2
                        </td>
                        <td className="px-6 py-4">
                        {item.Address2}
                        </td>
                    </tr>

                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Address Line 3
                        </td>
                        <td className="px-6 py-4">
                        {item.Address3}
                        </td>
                    </tr>

                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Address Line 4
                        </td>
                        <td className="px-6 py-4">
                        {item.Address4}
                        </td>
                    </tr>


                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Aadhar Card Front Image
                        </td>
                        <td className="px-6 py-4">
                        <Link className="font-medium text-blue-600 dark:text-blue-500 hover:underline" to={item.AadharCardUrl}>View Details</Link>
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Aadhar Card Back Image
                        </td>
                        <td className="px-6 py-4">
                        <Link className="font-medium text-blue-600 dark:text-blue-500 hover:underline" to={item.AadharCardUrl1}>View Details</Link>
                        </td>
                    </tr>


                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Pan Card Image
                        </td>
                        <td className="px-6 py-4">
                        <Link className="font-medium text-blue-600 dark:text-blue-500 hover:underline" to={item.PanCardUrl}>View Details</Link>
                        </td>
                    </tr>
                </tbody>

                ))
               }
               
            </table>
        </div>



        <div className="m-2 mt-10 relative overflow-x-auto shadow-md sm:rounded-lg">
           
           <h1 className=' px-6 py-2 mb-6 border rounded-2xl text-center text-sm font-bold text-gray-400 bg-gray-50 dark:bg-gray-700 dark:text-white'> User's Investment Plan </h1>
           
           {/* ivestment Plan of Customer */}
           
           {
            investentPlan.length > 0?<table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Particulars 
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Value
                    </th>
                </tr>
            </thead>
           
           {
            investentPlan.map((item)=>(
                <tbody>
                
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Invested Amount 
                    </td>
                    <td className="px-6 py-4">
                    {item.InvestedAmount}
                    </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        plan's Term
                    </td>
                    <td className="px-6 py-4">
                    {item.NumberOfYears}
                    </td>
                </tr>


                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        User's UPI Id
                    </td>
                    <td className="px-6 py-4">
                    {item.UserUpiID}
                    </td>
                </tr>


                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        User Payout Frequency
                    </td>
                    <td className="px-6 py-4">
                    {item.PayoutFrequencyOfUser}
                    </td>
                </tr>

                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        User's Payment Screenshot
                    </td>
                    <td className="px-6 py-4">
                    <Link className="font-medium text-blue-600 dark:text-blue-500 hover:underline" to={item.TransactionImage}>View Details</Link>
                    </td>
                </tr>

                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Approve Investement 
                    </td>
                    <td className="px-6 py-4">
                    <button onClick={() => handleDelete(item.id)} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Delete this
                        </button>
                    </td>
                </tr>
            </tbody>

            

            ))
           }
           
        </table>:<div></div>
           }
            

            {/* approved Investment Plan of Customer */}
{
    approvedPlan.length > 0 ?
    <div className="m-2 mt-10 relative overflow-x-auto shadow-md sm:rounded-lg">
           
    <h1 className=' px-6 py-2 mb-6 border rounded-2xl text-center text-sm font-bold text-gray-400 bg-gray-50 dark:bg-gray-700 dark:text-white'> User's Approved Investment Plan </h1>
    
    
    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
            <th scope="col" className="px-6 py-3">
                Particulars 
            </th>
            <th scope="col" className="px-6 py-3">
                Value
            </th>
        </tr>
    </thead>
   
   {
    approvedPlan.map((item)=>(
        <tbody>
        
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Invested Amount 
            </td>
            <td className="px-6 py-4">
            {item.InvestedAmount}
            </td>
        </tr>
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                plan's Term
            </td>
            <td className="px-6 py-4">
            {item.PlanTerm}
            </td>
        </tr>
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Number of Installment Paid
            </td>
            <td className="px-6 py-4">
            {item.NumberOfInstallmentPaid}
            </td>
        </tr>


        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                User's UPI Id
            </td>
            <td className="px-6 py-4">
            {item.UserUpiID}
            </td>
        </tr>


        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                User Payout Frequency
            </td>
            <td className="px-6 py-4">
            {item.PayoutFrequencyOfUser}
            </td>
        </tr>

        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Approve Investement 
            </td>
            <td className="px-6 py-4">
                <Link to={`/admin/83547-245/updateUserPayment/${item.id}`} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update
                            </Link>
            </td>
        </tr>
    </tbody>

    

    ))
   }
   
</table>  </div>:<div></div>
}
            
        </div>



        </div>
                </div>
            </div>
        </>
    

<div>
    {renderMyComponent()}
</div>
       
        </>
  )
}

export default UserData
