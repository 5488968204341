import {React, useState, useEffect} from 'react';
import {useUserContext} from "../Context/authContext/index"
import { db } from '../Firebase/Firebase';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore'
import { Link } from 'react-router-dom';


const UserDashboardItem = () => {
    const [investentPlan, setInvestmentplan] = useState([])
    const [oldInvestmentPlan, setOldInvestmentPlan] = useState([])
    const [userInfo, setUserInfo] = useState([])
    const {user} = useUserContext()
    // console.log(user.uid)
    const myUser = []
    const userDetails = ()=>{
        userInfo.forEach
            ((item)=>{(myUser.push(item.Firstname))})
            userInfo.forEach
            ((item)=>{(myUser.push(item.Lastname))})
            userInfo.forEach
            ((item)=>{(myUser.push(item.Phone))})
    }
    
    const handleprematurePayment = async (id)=>{
        userDetails()
        const MyUserName = myUser[0] + " " + myUser[1]
        const MyUserPhone =myUser[2]
        console.log(MyUserName)
        console.log(MyUserPhone)
        try {
            const docRef = doc(db, "Approved-plan", id);
            await updateDoc(docRef, {
                IsPrematureApplied:"true",
                Name:MyUserName,
                PhoneNumber:MyUserPhone
            });
            alert("You Applied for Pre-Mature withdrawal, We will revert back soon");
            // Optionally, redirect the user to the updated blog post page or any other page
        } catch (error) {
            console.error(error);
            alert("Failed to update blog post. Please try again.");
        }
    }

    async function fetchDataFromFirestore1() {
        const querySnapshot = await getDocs(collection(db, "Approved-plan"))
        const data = []
        querySnapshot.forEach((doc)=>{
          data.push({id: doc.id, ...doc.data()})
        })
        return data
      }

      async function fetchDataFromFirestore2() {
        const querySnapshot = await getDocs(collection(db, "investment-plan"))
        const data = []
        querySnapshot.forEach((doc)=>{
          data.push({id: doc.id, ...doc.data()})
        })
        return data
      }
      
      useEffect(()=>{
        async function fetchData(){
          const data = await fetchDataFromFirestore2()
          const userData = data.filter(item => item.USERID === user.uid);
          setOldInvestmentPlan(userData);
        }
        fetchData()
      },[user.uid])

      useEffect(()=>{
        async function fetchData(){
          const data = await fetchDataFromFirestore1()
          const userData = data.filter(item => item.USERID === user.uid);
          setInvestmentplan(userData);
          console.log(userData)
        }
        fetchData()
      },[user.uid])

      


      async function fetchDataFromFirestore() {
        const querySnapshot = await getDocs(collection(db, "user-info"))
        const data = []
        querySnapshot.forEach((doc)=>{
          data.push({id: doc.id, ...doc.data()})
        })
        return data
      }

      useEffect(()=>{
        async function fetchData(){
          const data = await fetchDataFromFirestore()
          const userData = data.filter(item => item.USERID === user.uid);
          setUserInfo(userData);
        //   console.log(userData)
        }
        fetchData()
      },[user.uid])


    return (
<>



{
    oldInvestmentPlan.length >0? <div className=" m-2 mt-10 block w-full p-6 bg-white border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-750 ">   
    <h5 className="mb-2 text-2xl font-bold tracking-tight text-center text-gray-900 dark:text-white">Notification</h5>
    <p className="font-normal text-gray-700 dark:text-gray-200 text-center"> {userInfo.map((item)=>(<h1>Congrats {item.Firstname} {item.Lastname} !!!!!!</h1>))}     We have received your new investment payment.</p> 
    <p className="font-normal text-gray-700 dark:text-gray-200 text-center">Your investment amount and your investment Plans will be refect within 1 business Day. </p> 

</div>:<div>

</div>
}

    

{ 
investentPlan.length>0 ?
<div>
    {
        investentPlan.map((item)=>(
            <div className="m-5 bg-white border border-6 shadow max-w-2xl shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-xl leading-6 font-bold font-medium text-gray-900">
                    Your Investment
                </h3>
                <p className="mt-1 max-w-2xl font-bold text-sm text-gray-500">
                    Details and information about the investment plan.
                </p>
            </div>
            <div className="border-t border-gray-200">
                <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Investment Plan's name
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            SAM Yealy Investment Plans
                        </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Premium payment 
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            Rs. {item.InvestedAmount}/-
                        </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Plan's Term
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {item.PlanTerm}
                        </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            No. Of Installment Paid
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {item.NumberOfInstallmentPaid}
                        </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Maturity Date 
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {item.MaturityDate}
                        </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Maturity Amount
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {item.MaturityAmount}
                        </dd>
                    </div>

                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Your UPI ID to receive Payment
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {item.UserUpiID}
                        </dd>
                    </div>
    
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Premature Withdrawal 
                        </dt>
                        <dd className="mt-3 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <Link onClick={()=>handleprematurePayment(item.id)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" >Request Withdrawal</Link>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
        ))
    }</div>:<div></div>
}







        </>
    );
};

export default UserDashboardItem;
