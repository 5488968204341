import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../Firebase/Firebase';
import { Link } from 'react-router-dom';

const BlogCards = () => {
  const [Blog, setBlog] = useState([]);

  async function fetchDataFromFirestore() {
    const querySnapshot = await getDocs(collection(db, "Blogs"));
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    return data;
  }

  useEffect(() => {
    async function fetchData() {
      const data = await fetchDataFromFirestore();
      setBlog(data);
    }
    fetchData();
  }, []);

  // Slice the Blog state to show only the first 3 items
  const limitedBlog = Blog.slice(0, 3);

  return (
    <section className="flex flex-col justify-center max-w-6xl min-h-screen px-4 py-10 mx-auto sm:px-6">
      <div className="flex flex-wrap items-center justify-between mb-8">
        <h4 className="mr-10 text-4xl font-bold leading-none md:text-4xl">
          Blogs & NEWS related to Investments
        </h4>
      </div>
      <div className="overflow-x-auto flex space-x-4 py-4">
        {limitedBlog.map((item) => (
          <div key={item.id} className="flex-shrink-0 w-full max-w-full mb-8 sm:w-1/2 px-4 lg:w-1/3 flex flex-col">
            <div className='border'>
              <img src={item.BlogImage} alt="Card img" className="object-cover object-center w-full h-48" />
              <div className="flex flex-grow w-full">
                <div className="flex flex-col w-full justify-between px-4 py-6 bg-white border border-gray-400 text">
                  <div>
                    <p className="block mb-4 text-2xl font-black leading-tight hover:underline hover:text-blue-600">
                      {item.Heading}
                    </p>
                    <p className="mb-4">
                      {item.SubHeading}
                    </p>
                  </div>
                  <div>
                    <Link className="inline-block pb-1 mt-2 text-base font-black text-blue-600 uppercase border-b border-transparent hover:border-blue-600" to={`/blog/${item.id}`}>Read More </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default BlogCards;
