
import { useState } from 'react'
import { Switch } from '@headlessui/react'
import Navbar from './Navbar'
import {useUserContext} from "../Context/authContext/index"
import { db } from '../Firebase/Firebase';
import { collection, addDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function EnquiryForm() {
  const [agreed, setAgreed] = useState(false)
  const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const {user} = useUserContext()
    const Navigate = useNavigate()

  const submitEnquiryForm = async (e)=>{
    e.preventDefault()

    const docRef = await addDoc(collection(db, "Enquiry"), {
      Firstname: firstName,
      Lastname: lastName,
      Phone: phone,
      Email: email,
      InvestmentPlan:selectedOption,
      USERID: user.uid
  });
  console.log(docRef)
  Navigate("/")
  alert("We will contact you soon")
  }

  return (
    <>
<header className='absolute inset-x-0 top-0 z-50'>
<Navbar/>
</header>
    
    <div className="isolate mt-10 bg-white px-6 py-10 sm:py-32 lg:px-8">
    <div className="absolute inset-x-0 top-[-10rem] sm:top-[-20rem] transform-gpu overflow-hidden blur-3xl">
  <div
    className="relative left-1/2 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
    style={{
      clipPath:
        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    }}
  />
</div>

      
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Enquiry Form</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Lets talk and discuss about our plans 
        </p>
      </div>
      <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
              First name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                required
                onChange={(e) => setFirstName(e.target.value)} 
                placeholder='Example : Elon'
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
              Last name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="last-name"
                onChange={(e) => setLastName(e.target.value)} 
                required
                placeholder='Example : Musk'
                id="last-name"
                autoComplete="family-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
            Select your Plan
            </label>
            <div className="mt-2.5">
            <select
                  id="plan"
                  name="plan"
                  required
                  onChange={(e) => setSelectedOption(e.target.value)} 
                  className=" w-full rounded-md border-100 bg-transparent bg-none py-2 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                >
                   <option value="" disabled selected>Select your option</option>
                  <option>SAM Goal Achiver 5 Yr</option>
                  <option>SAM Goal Achiver 6 Yr</option>
                  <option>SAM Goal Achiver 10 Yr</option>
                  <option>SAM Goal Achiver 20 Yr</option>
                  <option>SAM Goal Achiver 25 Yr</option>
                  <option>SAM SIP Goal 1 Yr</option>
                  <option>SAM SIP Goal 3 Yr</option>
                  <option>SAM SIP Goal 5 Yr</option>
                </select>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
              Email
            </label>
            <div className="mt-2.5">
              <input
                type="email"
                name="email"
                required
                onChange={(e) => setEmail(e.target.value)} 
                placeholder='Example : NA@gmail.com'
                id="email"
                autoComplete="email"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
              Phone number
            </label>
            <div className="relative mt-2.5">
              <div className="absolute inset-y-0 left-0 flex items-center">
                <label htmlFor="country" className="sr-only">
                  Country
                </label>
               
              </div>
              <input
                type="tel"
                name="phone-number"
                required
                onChange={(e) => setPhone(e.target.value)} 
                placeholder='Example : 9999999999'
                id="phone-number"
                autoComplete="tel"
                className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
         
         
        </div>
        <div className="mt-10">
          <button
            type="submit"
            onClick={submitEnquiryForm}
            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Let's talk
          </button>
        </div>
      </form>
    </div>
    </>
  )
}
