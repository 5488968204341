import { createContext, useContext, useEffect, useState } from "react";
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
import { auth, db } from "../../Firebase/Firebase";
import { useNavigate } from "react-router-dom";
import { collection, addDoc, getDocs } from "firebase/firestore";

const UserContext = createContext({})

export const useUserContext =()=> useContext(UserContext)
 
export const UserContextProvider =({children})=>{
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState()
    const [error, setError] = useState("")
    const [userInfo, setUserInfo] = useState([])
    const Navigate = useNavigate()

    useEffect(()=>{
        setLoading(true)
        const unsubscribe = onAuthStateChanged(auth, res=>{
            res? setUser(res):setUser(null)
            setError("")
            setLoading(false)
        })
        return unsubscribe
    },[])

    const registerUser = async (email, password, displayName, phone) => {
        setLoading(true);
        try {
          // Create user account
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;
    console.log(user)
    
    await SendEmailVerification(user);

    
          // Save user data to Firestore
           await addDoc(collection(db, "users"), {
            email: user.email,
            displayName: displayName,
            PhoneNumber:phone,
            USERID:user.uid,
            Role:"Customer"
          });
    
          console.log('User registered and data saved to Firestore:', user);
          logoutUser()
          return user;
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
          Navigate("/login")
        }
      };

      const SendEmailVerification = async (user) => {
        try {
            await sendEmailVerification(auth.currentUser);
            console.log('Email verification sent');
        } catch (error) {
            console.error('Error sending email verification:', error);
        }
    };

    const signInUser = async (email, password) => {
      // Assuming `user` is defined or initialized somewhere before this function is called
      // Check if `user` exists and has the `emailVerified` property
     await  signInWithEmailAndPassword(auth, email, password)
       console.log(user.emailVerified);
      if (user.emailVerified) {
          LoginMe()
  
          
      } else {
          console.error("User object is null or does not have 'emailVerified' property defined.");
          // Handle the case where user or user.emailVerified is null or undefined
      }
  };
  

    async function fetchDataFromFirestore() {
        const querySnapshot = await getDocs(collection(db, "users"))
        const data = []
        querySnapshot.forEach((doc)=>{
          data.push({id: doc.id, ...doc.data()})
        })
        return data
      }

      
    const LoginMe = async ()=>{
        const data = await fetchDataFromFirestore()
        console.log(auth.currentUser.uid)
        console.log(data) 
        const userData = data.filter(item => item.USERID === auth.currentUser.uid);
        setUserInfo(userData);
        if(userData[0].Role == "Admin"){
          Navigate("/admin/83547-245")
          console.log("Admin")
        }
        else{
          Navigate("/user/dashboard")
          console.log("User")
        }

    }

    const logoutUser =()=>{
        signOut(auth)
    }

    const forgotPassword =(email)=>{
        return sendPasswordResetEmail(auth, email)
    }

    const contextValue= {user, loading, error, forgotPassword, logoutUser, signInUser, registerUser}

    return(
        <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
    )
}