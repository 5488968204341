import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import BlogOneCard from './BlogOneCard';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../Firebase/Firebase';
import { useParams } from 'react-router-dom';

const Blog = () => {
  const params = useParams();
  const [Blog, setBlog] = useState([]);

  async function fetchDataFromFirestore() {
    const querySnapshot = await getDocs(collection(db, "Blogs"));
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    return data;
  }

  useEffect(() => {
    async function fetchData() {
      const data = await fetchDataFromFirestore();
      const userData = data.filter(item => item.id === params.id);
      setBlog(userData);
      console.log(data);
      console.log(params.id);
    }
    fetchData();
  }, [params.id]);

  return (
    <>
      <Navbar />
      <div className="flex flex-col">
        <div className="bg-gray-100 py-8">
          <div className="container mx-auto px-4">
            <h1 className="text-4xl font-bold text-gray-800 mb-2 text-center">Blog Title Here</h1>
            <p className="text-gray-600 text-center">Published on April 4, 2023</p>
          </div> 
        </div>
        <div className="bg-white py-8">
          <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-center">
            <div className="overflow-x-auto flex space-x-4">
              {Blog.map((item) => (
                <div key={item.id} className="flex-shrink-0 w-full md:w-3/4 px-4">
                  <img src={item.BlogImage} alt="Blog Featured Image" className="mb-8" />
                  <div className="prose max-w-none">
                    <p className='text-3xl font-bold'>{item.Heading}</p>
                    <p className='text-xl font-bold'>{item.SubHeading}</p>
                    <p>{item.Content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <BlogOneCard />
    </>
  );
};

export default Blog;
