import {React, useState, useEffect} from 'react';
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../Firebase/Firebase';
import { Link } from 'react-router-dom';
const UsersTable = () => {

    const [allUsers, setAllusers] = useState([])
    

    async function fetchDataFromFirestore() {
        const querySnapshot = await getDocs(collection(db, "users"))
        const data = []
        querySnapshot.forEach((doc)=>{
          data.push({id: doc.id, ...doc.data()})
        })
        return data
      }

      useEffect(()=>{
        async function fetchData(){
          const data = await fetchDataFromFirestore()
          setAllusers(data);
          console.log(data)
          
        }
        fetchData()
      },[])


    return (
       
       <div>
        <div className='my-8 m-2 '>
        <h1 className=' px-6 py-3 border rounded-2xl text-center text-m font-bold text-gray-400 bg-gray-50 dark:bg-gray-700 dark:text-white'>List of All users Registered on your website</h1>
        </div>
       <div className="m-2 relative overflow-x-auto shadow-md sm:rounded-lg">
           
           
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            User's name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Mobile Number
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Email ID
                        </th>
                        
                        <th scope="col" className="px-6 py-3">
                            <span className="sr-only">Edit</span>
                        </th>
                    </tr>
                </thead>
               
               {
                allUsers.map((item)=>(
                    <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {item.displayName}
                        </td>
                        <td className="px-6 py-4">
                        {item.PhoneNumber}
                        </td>
                        <td className="px-6 py-4">
                        {item.email}
                        </td>
                        
                        <td className="px-6 py-4 text-right">
                           <Link className="font-medium text-blue-600 dark:text-blue-500 hover:underline" to={`/admin/83547-245/userdata/${item.USERID}`}>View Details</Link>
                        </td>
                    </tr>
                </tbody>
                ))
               }
               
            </table>
        </div>
        </div>
    );
};

export default UsersTable;
