import React from 'react';
import PlanCard from '../PlanCard'; // Adjust the import path as necessary

const plans = [
  {
    title: "Goal Achiever 5 year Plan",
    investment: "10,000",
    features: [
      "Invest in yearly",
      "Get maximum interest",
      "Get 30% of your Annual Premium back",
      "Receive your invested amount back after maturity",
      "Best in the segment",
      "Better than FD",
      "Risk Free Investment",
      "Fixed return"
    ]
  },
  {
    title: "Goal Achiever 10 year Plan",
    investment: "10,000",
    features: [
      "Invest in yearly",
      "Get maximum interest",
      "Get 30% of your Annual Premium back",
      "Receive your invested amount back after maturity",
      "Also get interest on your invested amount",
      "Best in the segment",
      "Better than FD",
      "Risk Free Investment",
      "Fixed return"
    ]
  },
  {
    title: "Goal Achiever 20 year Plan",
    investment: "10,000",
    features: [
      "Invest in yearly",
      "Get maximum interest",
      "Get 30% of your Annual Premium back",
      "Receive your invested amount back after maturity",
      "Also get interest on your invested amount",
      "Best in the segment",
      "Better than FD",
      "Risk Free Investment",
      "Fixed return"
    ]
  }
];

const Billingsection = () => {
  return (
    <section className='m-7'>
      <div className="border bg-[#5E6A72] rounded-3xl max-w-7xl px-4 sm:px-6 pb-10 lg:px-8">
        <div className="mb-12 ">
          <h2 className="font-manrope text-5xl sm:text-center text-center font-bold mt-10 text-gray-100 mb-10">Choose your plan</h2>
          <p className="text-gray-100 text-center sm:text-center text-xl leading-6 mb-9">Please select from our Trending Plans</p>
        </div>
        <div className="overflow-x-auto lg:overflow-hidden space-y-8 lg:space-y-0">
          <div className="flex lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-8 lg:items-center space-x-4 lg:space-x-0">
            {plans.map((plan, index) => (
              <div key={index} className="flex-shrink-0">
                <PlanCard
                  title={plan.title}
                  investment={plan.investment}
                  features={plan.features}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Billingsection;
