import React, { useState } from 'react'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import { db } from '../Firebase/Firebase';

const CreateBlog = () => {
  const [heading, setHeading ] =useState("")
  const [subHeading, setSubHeading ] =useState("")
  const [content, setContent ] =useState("")
  const [blogImage, setBlogImage ] =useState(null)

  const storage = getStorage();
  const handlefinalStep = async (e) => {
    e.preventDefault();
  
    try {
        const blogImageRef = ref(storage, `images/${blogImage.name}`);
      
  
        // Upload blog image
        const blogImageSnapshot = await uploadBytesResumable(blogImageRef, blogImage);
        const blogImageURL = await getDownloadURL(blogImageSnapshot.ref);
  
        // Save form data along with image URLs to Firestore
        const docRef = await addDoc(collection(db, "Blogs"), {
            Heading: heading,
            SubHeading: subHeading,
            Content: content,
            BlogImage: blogImageURL,
            
        });
  
        
        alert("Blog posted");
        setHeading("")
        setSubHeading("")
        setContent("")
        
    } catch (error) {
        console.error("Error adding document: ", error);
    }
  };


  return (
    <div className='bg'>

        <h1 className='text-center font-bold text-3xl my-8 w-full bg-[#374251] text-gray-300 border rounded-3xl p-3'>Create a Blog post</h1>
      <form className=" w-[70vw] mx-auto bg">


        <div className="mb-5">
                <label htmlFor="base-input" className="block mb-2 text-m font-bold font-medium text-gray-900 dark:text-gray-900">Blog Heading</label>
                <input onChange={(e) => setHeading(e.target.value)}  placeholder='heading' type="text" id="base-input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
        </div>

        <div className="mb-5">
                <label htmlFor="base-input" className="block mb-2 text-m font-medium text-gray-900 dark:text-gray-900">Blog Sub-Heading</label>
                <input onChange={(e) => setSubHeading(e.target.value)}  placeholder='Sub heading' type="text" id="base-input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
        </div>

        <div className="mb-5">
                <label htmlFor="base-input" className="block mb-2 text-m font-medium text-gray-900 dark:text-gray-900">Blog Sub-Heading</label>
                <textarea onChange={(e) => setContent(e.target.value)}  id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Content"></textarea>
        </div>

        <div>
        <label htmlFor="base-input" className="block mb-2 text-m font-medium text-gray-900 dark:text-gray-900">Upload image</label>
                <input onChange={(e) => setBlogImage(e.target.files[0])} class="block p-2.5 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file"/>
        </div>

        <button onClick={handlefinalStep} class="text-white mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Create Blog Post </button>
      </form>
    </div>
  )
}

export default CreateBlog
