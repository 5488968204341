
import React from 'react';
import { Link } from 'react-router-dom';
import logo from "./assest/logo.jpeg"

const Footer = () => {
  return (
    <footer className="px-3 pt-4 lg:px-9 border-t-2 bg-gray-400">
      <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
        <div className="sm:col-span-2">
          <Link href="/" to={"/"} className="inline-flex items-center">
            <img src={logo} alt="logo" className="h-10 w-10 rounded-full" />
            <span className="ml-2 text-xl font-bold tracking-wide text-gray-800">Sharma Asset Management Company</span>
          </Link>
          <div className="mt-6 lg:max-w-xl">
            <p className="text-sm text-gray-800">
            Sharma Asset Management is a leading investment firm dedicated to helping clients achieve their financial goals through strategic investment solutions. With a commitment to excellence, integrity, and innovation, we provide tailored investment strategies and personalized service to individuals, families, and institutions.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2 text-sm">
          <p className="text-base font-bold tracking-wide text-gray-900">Popular Plans</p>
          <Link className='ml-1' href="/">Monthly Inestment Plans</Link>
          <Link className='ml-1' href="/">Short Term Yealy Plans</Link>
          <Link className='ml-1' href="/">Long Term Yearly Plans</Link>
          <p className="text-base mt-5 font-bold tracking-wide text-gray-900">Popular Topics</p>
          <Link className='ml-1' href="/">Mutul Funds</Link>
          <Link className='ml-1' href="/">Fixed Income investment</Link>
          <Link className='ml-1' href="/">Stock Market Investments</Link>
        </div>
        <div>
          <p className="text-base font-bold tracking-wide text-gray-900">COMPANY IS ALSO AVAILABLE ON</p>
          <div className="flex items-center gap-1 px-2">
            <Link to={"https://www.instagram.com/sharmaassetmanagement?igsh=YjlwdDRqNWY5MGk3&utm_source=qr"} className="w-full min-w-xl">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/600px-Instagram_icon.png" alt="Playstore Button" className="h-10" />
            </Link>
            <Link className="w-full min-w-xl" href="https://www.youtube.com/channel/UCo8tEi6SrGFP8XG9O0ljFgA">
              <img src="https://mcqmate.com/public/images/icons/youtube.svg" alt="Youtube Button" className="h-28" />
            </Link>
          </div>
          <p className="text-base font-bold tracking-wide text-gray-900">Contacts</p>
          <div className="flex">
            <p className="mr-1 text-gray-800">Email:</p>
            <Link href="/" title="send email">admin@company.com</Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
        <p className="text-sm text-gray-600">© Copyright 2023 Company. All rights reserved.</p>
        <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
          <li>
            <Link href="/" className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">Privacy &amp; Cookies Policy</Link>
          </li>
          <li>
            <Link href="/" className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">Disclaimer</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
