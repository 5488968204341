import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useUserContext } from "../Context/authContext/index";
import { db } from "../Firebase/Firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { CircularIndeterminate } from "./loadanimation";

const CheckOutForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [investmentAmount, setInvestmentAmount] = useState("");
  const [years, setYears] = useState("");
  const [payoutFrequency, setPayoutFrequency] = useState("");
  const [UPIID, setUPIID] = useState("");
  const [transactionImage, setTransactionImage] = useState(null);
  const [kyc, setKYC] = useState([])
  const [kycData, setKYCData] = useState(false)
  const [loading, setLoading] = useState(false)

  const Navigate = useNavigate();

  const { user } = useUserContext();

  const storage = getStorage();

  async function fetchDataFromFirestore() {
    const querySnapshot = await getDocs(collection(db, "user-info"))
    const data = []
    querySnapshot.forEach((doc)=>{
      data.push({id: doc.id, ...doc.data()})
    })
    return data
  }

  useEffect(()=>{
    async function fetchData(){
      const data = await fetchDataFromFirestore()
      const userData = data.filter(item => item.USERID === user.uid);
      setKYC(userData);
      if(userData.length ===0){
        console.log(userData.length)
         setKYCData(false)
         console.log(kycData)
        
      }
      else{
        console.log("hello")
        
        console.log(kyc)
        setKYCData(true)
        console.log(kycData)
      }
    }
    fetchData()
  },[])

  const handleNextStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep + 1);
  };

  const handlefinalStep = async (e) => {
    e.preventDefault();
    setLoading(true)

    try {
      const transactionRef = ref(storage, `images/${transactionImage.name}`);
      const transactionSnapshot = await uploadBytesResumable(
        transactionRef,
        transactionImage
      );
      const transactonCardUrl = await getDownloadURL(transactionSnapshot.ref);

      const docRef1 = await addDoc(collection(db, "investment-plan"), {
        InvestedAmount: investmentAmount,
        NumberOfYears: years,
        PayoutFrequencyOfUser: payoutFrequency,
        UserUpiID: UPIID,
        USERID: user.uid,
        TransactionImage: transactonCardUrl,
      });
      console.log("Document written with ID: ", docRef1.id);
      console.log(user.uid);
      Navigate("/user/dashboard");
      alert("your payment will reflect on your dashboard within 24 hours");
      setLoading(false)
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const steps = ["Select investment plan", "Final Payment"];

  const renderStepForm = () => {
    switch (currentStep) {
      case 0:
        return (
          <div>
            <div className="isolate  bg-white px-6 py-10 sm:py-32 lg:px-8">
              <div className="absolute inset-x-0 top-[-10rem] sm:top-[-20rem] transform-gpu overflow-hidden blur-3xl">
                <div
                  className="relative left-1/2 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                  style={{
                    clipPath:
                      "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                  }}
                />
              </div>

              <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Select your Investment plan
                </h2>
              </div>

              <form
                action="#"
                method="POST"
                className="mx-auto mt-16 max-w-xl sm:mt-20"
              >
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                  <>
                    <div>
                      <label
                        htmlFor="yearly-investment-amount"
                        className="block text-sm font-semibold leading-6 text-gray-900"
                      >
                        Enter your Yearly investment Amount
                      </label>
                      <div className="mt-2.5">
                        <input
                          type="number"
                          required
                          onChange={(e) => setInvestmentAmount(e.target.value)}
                          placeholder="Example: 1,00,000/-"
                          name="investment-amount"
                          id="yearly-investment-amount"
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="yearly-duration"
                        className="block text-sm font-semibold leading-6 text-gray-900"
                      >
                        Choose number of years to invest
                      </label>
                      <div className="mt-2.5">
                        <select
                          id="yearly-duration"
                          name="yearly-duration"
                          required
                          onChange={(e) => setYears(e.target.value)}
                          className="w-full rounded-md border-100 bg-transparent bg-none py-2 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                        >
                          <option>Select Years</option>
                          <option>5 Years</option>
                          <option>6 Years</option>
                          <option>10 Years</option>
                          <option>20 Years</option>
                          <option>25 Years</option>
                        </select>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="yearly-interest-payout"
                          className="block text-sm font-semibold leading-6 text-gray-900"
                        >
                          Choose your yearly interest payout
                        </label>
                        <div className="relative mt-2.5">
                          <select
                            id="yearly-interest-payout"
                            name="yearly-interest-payout"
                            required
                            onChange={(e) => setPayoutFrequency(e.target.value)}
                            className="w-full rounded-md border-100 bg-transparent bg-none py-2 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                          >
                            <option>Select Payout Frequency</option>
                            <option>Yearly Payout</option>
                            <option>Half Yearly Payout</option>
                            <option>Quarterly Payout</option>
                            <option>Monthly Payout</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </>

                  <div>
                    <label
                      htmlFor="upi-id"
                      className="block text-sm font-semibold leading-6 text-gray-900"
                    >
                      Enter your UPI ID to receive your payouts
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="text"
                        required
                        onChange={(e) => setUPIID(e.target.value)}
                        placeholder="Example: yourname@upi"
                        name="upi-id"
                        id="upi-id"
                        className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-10 flex">
                  <button
                    type="submit"
                    className="block w-full m-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleNextStep}
                  >
                    Next Step
                  </button>
                </div>
              </form>
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <div className="isolate  bg-white px-6 py-10 sm:py-32 lg:px-8">
              <div className="absolute inset-x-0 top-[-10rem] sm:top-[-20rem] transform-gpu overflow-hidden blur-3xl">
                <div
                  className="relative left-1/2 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                  style={{
                    clipPath:
                      "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                  }}
                />
              </div>
              <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Final Payment
                </h2>
              </div>
              <form
                action="#"
                method="POST"
                className="mx-auto mt-16 max-w-xl sm:mt-20"
              >
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="phone-number"
                      className="block text-sm font-semibold leading-6 text-gray-900"
                    >
                      This is our UPI ID specially for you
                    </label>
                    <div className="relative mt-2.5">
                      <input
                        type="text"
                        required
                        readOnly
                        placeholder="sharmaassetmanagement@kotak"
                        name="upi-id"
                        id="upi-id"
                        className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div class="font-[sans-serif] max-w-md ">
                    <label class="text-base text-gray-500 font-semibold mb-2 block">
                      Upload your screenshot of payment
                    </label>
                    <input
                      type="file"
                      required
                      onChange={(e) => setTransactionImage(e.target.files[0])}
                      class="w-full text-gray-400 font-semibold text-sm bg-white border file:cursor-pointer cursor-pointer file:border-0 file:py-2 file:px-4 file:mr-4 file:bg-gray-100 file:hover:bg-gray-200 file:text-gray-500 rounded"
                    />
                    <p class="text-xs text-gray-400 mt-2">
                      Only JPG is Allowed.
                    </p>
                  </div>
                </div>
                
                  
{
  loading? <div><CircularIndeterminate/></div>:  <div className="mt-10 flex"><button
  type="submit"
  className="block m-2 w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  onClick={handlePreviousStep}
>
  Previous Step
</button> <button
  type="submit"
  onClick={handlefinalStep}
  className="block m-2 w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
>
  Submit
</button></div> 
}

                  
                
              </form>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <header className="absolute inset-x-0 top-0 z-50">
        <Navbar />
      </header>

      <div className="isolate mt-7 bg-white  pt-14 ">
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={currentStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </div>

      {
        user? <div>{
          kycData? <div>{renderStepForm()}</div>:<div className="text-center mt-20 "> <h1 className="text-red text-3xl font-bold ">Please complete your KYC first from user Dashboard before investing</h1> <br /> <br /><br /><br /><Link to={"/KYC"} className="text-blue ">Click here for KYC</Link></div>
        }</div>:<div className="text-center mt-10">
          <h1 className=" text-2xl font-bold mt-10"> Please Login for investment </h1>
          <p> <span className="text-blue-700"> <Link to={'/signup'}>click here</Link></span> for login</p>
        </div>
      }

      

      
    </>
  );
};

export default CheckOutForm;
