import {React, useState, useEffect} from 'react'
import {useUserContext} from "../Context/authContext/index"
import { db } from '../Firebase/Firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { CircularIndeterminate } from './loadanimation';
 
const KYC = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [address3, setAddress3] = useState('');
    const [address4, setAddress4] = useState('');
    const [email, setEmail] = useState('');
    const [aadharNumber, setAadharNumber] = useState('');
    const [panNumner, setPanNumber] = useState('');
    const [aadharVirtual, setAadharVirtual] = useState('');
    const [aadharCardImage, setAadharCardImage] = useState(null);
    const [aadharCardImage1, setAadharCardImage1] = useState(null);
    const [panCardImage, setPanCardImage] = useState(null);
    const [kyc, setKYC] = useState([])
    const [kycData, setKYCData] = useState(false)
    const [loading, setLoading] = useState(false)

    const {user} = useUserContext()
    const Navigate = useNavigate()
    const storage = getStorage();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const Navigation = useNavigate()

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const {logoutUser} = useUserContext()

  const Logout = ()=>{
    logoutUser()
    alert("User Logout sussecfully")
    Navigation("/")
  }
  async function fetchDataFromFirestore() {
    const querySnapshot = await getDocs(collection(db, "user-info"))
    const data = []
    querySnapshot.forEach((doc)=>{
      data.push({id: doc.id, ...doc.data()})
    })
    return data
  }

  useEffect(()=>{
    async function fetchData(){
      const data = await fetchDataFromFirestore()
      const userData = data.filter(item => item.USERID === user.uid);
      setKYC(userData);
      if(userData.length ===0){
        console.log(userData.length)
         setKYCData(true)
         console.log(kycData)
        
      }
      else{
        console.log("hello")
        console.log(kycData)
        console.log(kyc)
        setKYCData(false)
      }
    }
    fetchData()
  },[user.uid])




    const handleNextStep = async (e)=>{
        e.preventDefault();
        setLoading(true)

        try {
            const aadharCardRef = ref(storage, `images/${aadharCardImage.name}`);
            const aadharCardRef1 = ref(storage, `images/${aadharCardImage1.name}`);
            const panCardRef = ref(storage, `images/${panCardImage.name}`);
      
            // Upload Aadhar Card image
            const aadharSnapshot = await uploadBytesResumable(aadharCardRef, aadharCardImage);
            const aadharCardUrl = await getDownloadURL(aadharSnapshot.ref);

            const aadharSnapshot1 = await uploadBytesResumable(aadharCardRef1, aadharCardImage1);
            const aadharCardUrl1 = await getDownloadURL(aadharSnapshot1.ref);
      
            // Upload Pan Card image
            const panSnapshot = await uploadBytesResumable(panCardRef, panCardImage);
            const panCardUrl = await getDownloadURL(panSnapshot.ref);
            
            const docRef = await addDoc(collection(db, "user-info"), {
                Firstname: firstName,
                Lastname: lastName,
                Phone: phone,
                Email: email,
                Address1: address1,
                Address2: address2,
                Address3: address3,
                Address4: address4,
                AadharNumber: aadharNumber,
                AadharVirtualNumber: aadharVirtual,
                PanNumber: panNumner,
                AadharCardUrl: aadharCardUrl,
                AadharCardUrl1: aadharCardUrl1,
                PanCardUrl: panCardUrl,
                USERID: user.uid
            });
            console.log(docRef)
            setLoading(false)
            Navigate("/plans")
      
    }catch (error) {
        console.error("Error adding document: ", error);
    }
    
}


  return (
    <div>

      <button
        onClick={toggleSidebar}
        aria-controls="default-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Toggle sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="default-sidebar"
        className={`fixed top-15 left-0 z-40 w-64 h-screen transition-transform ${
          sidebarOpen ? '' : '-translate-x-full sm:translate-x-0'
        }`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
          <button
            onClick={closeSidebar}
            aria-label="Close sidebar"
            className="absolute top-2 right-2 p-2 text-gray-500 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          >
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.414 4.586a2 2 0 012.828 0L10 7.172l1.758-1.758a2 2 0 012.828 2.828L12.828 10l1.758 1.758a2 2 0 01-2.828 2.828L10 12.828l-1.758 1.758a2 2 0 01-2.828-2.828L7.172 10 5.414 8.242a2 2 0 010-2.828z"
              ></path>
            </svg>
          </button>
          <ul className="mt-10 space-y-2 font-medium">
            


         <li>
            <Link to={"/"} href="/" class="pt-20 flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                  <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
               </svg>
               <span class="flex-1 ms-3 whitespace-nowrap">Home Page</span>
            </Link>
         </li>

         <li>
            <Link to={"/KYC"} href="/KYC" class=" flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                  <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
               </svg>
               <span class="flex-1 ms-3 whitespace-nowrap">KYC & Profile</span>
            </Link>
         </li>

         <li>
            <Link to={"/plans"} href="/plans" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                  <path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z"/>
               </svg>
               <span class="flex-1 ms-3 whitespace-nowrap">Investment Plans</span>
            </Link>
         </li>


         <li>
              <Link to={"/calculators"}
                href="/calculators"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <svg
                  className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 21"
                >
                  <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                  <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                </svg>
                <span className="ms-3">Calculators</span>
              </Link>
            </li>

         <li>
            <Link href="#"  class="flex absolute items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"/>
               </svg>
               <span onClick={Logout} class="flex-1 ms-3 whitespace-nowrap">Logout</span>
            </Link>
         </li>

                     </ul>
        </div>
      </aside>
<div>
{kycData ? <div className="p-4 sm:ml-64">

    <div className="p-4 m-2 text-center text-3xl font-bold bg-[#36454F] text-white  border-2 border-gray-200 border-dashed rounded-3xl dark:border-gray-700">
    Welcome User
    </div>

      {/* Personal Information  */}
    <div>
                     <div className="isolate  bg-white px-6 pt-5 lg:px-8">
  {/* <div className="absolute inset-x-0 top-[-10rem] sm:top-[-20rem] transform-gpu overflow-hidden blur-3xl">
<div
  className="relative left-1/2 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
  style={{
    clipPath:
      'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
  }}
/>
</div> */}

    
    <div className="mx-auto max-w-2xl text-center">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Personal Information</h2>
    </div>
    <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
      <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        <div>
          <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
            First name
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              onChange={(e) => setFirstName(e.target.value)} 
              required
              placeholder='Example : Elon'
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
            Last name
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              onChange={(e) => setLastName(e.target.value)} 
              name="last-name"
              required
              placeholder='Example : Musk'
              id="last-name"
              autoComplete="family-name"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        
        
        <div className="sm:col-span-2">
          <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
            Phone number
          </label>
          <div className="relative mt-2.5">
            <input
              type="tel"
              onChange={(e) => setPhone(e.target.value)} 
              name="phone-number"
              required
              placeholder='Example : 9999999999'
              id="phone-number"
              autoComplete="tel"
              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="sm:col-span-2">
          <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
            Email
          </label>
          <div className="mt-2.5">
            <input
              type="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)} 
              required
              placeholder='Example : NA@gmail.com'
              id="email"
              autoComplete="email"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="sm:col-span-2">
          <label htmlFor="Address" className="block text-sm font-semibold leading-6 text-gray-900">
            Address
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              onChange={(e) => setAddress1(e.target.value)} 
              name="Address"
              required
              placeholder='H. No. and Colony'
              id="Address"
              className="block w-full mb-3 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />

            <input
              type="text"
              onChange={(e) => setAddress2(e.target.value)} 
              name="Address"
              required
              placeholder='Street And Post '
              id="Address"
              className="block w-full rounded-md border-0 mb-3 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />

              <input
              type="text"
              onChange={(e) => setAddress3(e.target.value)} 
              name="Address"
              required
              placeholder='District '
              id="Address"
              className="block w-full mb-3 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />

            <input
              type="text"
              onChange={(e) => setAddress4(e.target.value)} 
              required
              placeholder='State'
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
       
      </div>
      
    </form>
  </div>
    </div>

    {/* KYC Documents */}
    <div>
                      <div className="isolate  bg-white px-6 pt-5  lg:px-8">

    <div className="mx-auto max-w-2xl text-center">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">KYC Documents</h2>
    </div>
    <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
      <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        <div>
          <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
            Aadhar Card Number 
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              onChange={(e) => setAadharNumber(e.target.value)} 
              required
              placeholder='Example : 1234 1234 1234'
              name="Aadhar-Card-Number"
              id="Aadhar-Card-Number"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
            Aadhar Card Vertual ID number 
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="last-name"
              onChange={(e) => setAadharVirtual(e.target.value)} 
              required
              placeholder='Example : 1234 1234 1234 1234'
              id="Virtual-Number"
              autoComplete="Virtual-Number"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        
        
        <div className="sm:col-span-2">
          <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
            Pan Card Number
          </label>
          <div className="relative mt-2.5">
            <input
              type="text"
              name="Pan-Card"
              onChange={(e) => setPanNumber(e.target.value)} 
              required
              placeholder='Enter Your 10 Digit Pan Card Number'
              id="Pan Card"
              autoComplete="PAN-card"
              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div class="font-[sans-serif] max-w-md">
    <label class="text-base text-gray-500 font-semibold mb-2 block">Upload front of Aadhar Card</label>
    <input type="file"
    required
    onChange={(e) => setAadharCardImage(e.target.files[0])}
      class="w-full text-gray-400 font-semibold text-sm bg-white border file:cursor-pointer cursor-pointer file:border-0 file:py-2 file:px-4 file:mr-4 file:bg-gray-100 file:hover:bg-gray-200 file:text-gray-500 rounded" />
    <p class="text-xs text-gray-400 mt-2">Only JPG are Allowed.</p>
  </div>

  <div class="font-[sans-serif] max-w-md">
    <label class="text-base text-gray-500 font-semibold mb-2 block">Upload back of Aadhar Card</label>
    <input type="file"
    required
    onChange={(e) => setAadharCardImage1(e.target.files[0])}
      class="w-full text-gray-400 font-semibold text-sm bg-white border file:cursor-pointer cursor-pointer file:border-0 file:py-2 file:px-4 file:mr-4 file:bg-gray-100 file:hover:bg-gray-200 file:text-gray-500 rounded" />
    <p class="text-xs text-gray-400 mt-2">only JPG are Allowed.</p>
  </div>

  <div class="font-[sans-serif] max-w-md ">
    <label class="text-base text-gray-500 font-semibold mb-2 block">Upload Pan Card</label>
    <input type="file"
    required
    onChange={(e) => setPanCardImage(e.target.files[0])}
      class="w-full text-gray-400 font-semibold text-sm bg-white border file:cursor-pointer cursor-pointer file:border-0 file:py-2 file:px-4 file:mr-4 file:bg-gray-100 file:hover:bg-gray-200 file:text-gray-500 rounded" />
    <p class="text-xs text-gray-400 mt-2">only JPG are Allowed.</p>
  </div>
       
      </div>
      <div className="mt-10 flex">
        
        {loading? <div className='flex '><CircularIndeterminate/></div>: <button
          type="submit"
          className="block w-full m-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={handleNextStep}
        >
          Submit
        </button>}
       
      </div>
    </form>
  </div>
    </div>
    </div>:<div className="p-4 sm:ml-64">
    <div className="p-4 m-2 text-center text-3xl font-bold bg-[#36454F] text-white  border-2 border-gray-200 border-dashed rounded-3xl dark:border-gray-700">
    Welcome User
    </div>
    <div className='Main-content-container-div'>
                <div>
        <div className='my-8 m-2 '>
        <h1 className=' px-6 py-3 border rounded-2xl text-center text-m font-bold text-gray-400 bg-gray-50 dark:bg-gray-700 dark:text-white'>User Information</h1>
        </div>
       
       
       <div className="m-2 relative overflow-x-auto shadow-md sm:rounded-lg">
           
           <h1 className=' px-6 py-2 mb-6 border rounded-2xl text-center text-sm font-bold text-gray-400 bg-gray-50 dark:bg-gray-700 dark:text-white'> User's Personal Information </h1>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Particulars 
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Value
                        </th>
                    </tr>
                </thead>
               
               {
                kyc.map((item)=>(
                    <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            First Name 
                        </td>
                        <td className="px-6 py-4">
                        {item.Firstname}
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Last Name 
                        </td>
                        <td className="px-6 py-4">
                        {item.Lastname}
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Phone Number
                        </td>
                        <td className="px-6 py-4">
                        {item.Phone}
                        </td>
                    </tr>


                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Email 
                        </td>
                        <td className="px-6 py-4">
                        {item.Email}
                        </td>
                    </tr>


                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Aadhar Card Number
                        </td>
                        <td className="px-6 py-4">
                        {item.AadharNumber}
                        </td>
                    </tr>


                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Aadhar Vertual ID
                        </td>
                        <td className="px-6 py-4">
                        {item.AadharVirtualNumber}
                        </td>
                    </tr>

                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                           Pan Card Number
                        </td>
                        <td className="px-6 py-4">
                        {item.PanNumber}
                        </td>
                    </tr>

                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Address Line 1 
                        </td>
                        <td className="px-6 py-4">
                        {item.Address1}
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Address Line 2
                        </td>
                        <td className="px-6 py-4">
                        {item.Address2}
                        </td>
                    </tr>

                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Address Line 3
                        </td>
                        <td className="px-6 py-4">
                        {item.Address3}
                        </td>
                    </tr>

                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Address Line 4
                        </td>
                        <td className="px-6 py-4">
                        {item.Address4}
                        </td>
                    </tr>


                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Aadhar Card Front Image
                        </td>
                        <td className="px-6 py-4">
                        <Link className="font-medium text-blue-600 dark:text-blue-500 hover:underline" to={item.AadharCardUrl}>View Details</Link>
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Aadhar Card Back Image
                        </td>
                        <td className="px-6 py-4">
                        <Link className="font-medium text-blue-600 dark:text-blue-500 hover:underline" to={item.AadharCardUrl1}>View Details</Link>
                        </td>
                    </tr>


                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Pan Card Image
                        </td>
                        <td className="px-6 py-4">
                        <Link className="font-medium text-blue-600 dark:text-blue-500 hover:underline" to={item.PanCardUrl}>View Details</Link>
                        </td>
                    </tr>
                </tbody>

                ))
               }
               
            </table>
        </div>



        </div>
                </div>

    </div>
}
</div>

      

    </div>
  )
}

export default KYC
