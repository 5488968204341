import {React, useState, useEffect} from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore'
import { db } from '../Firebase/Firebase';

const EnquiryTable = () => {

    const [enquiry, setEnquiry] = useState([])
    

    async function fetchDataFromFirestore() {
        const querySnapshot = await getDocs(collection(db, "Enquiry"))
        const data = []
        querySnapshot.forEach((doc)=>{
          data.push({id: doc.id, ...doc.data()})
        })
        return data
      }

      const handleDelete = async (id)=>{

        try {
            await deleteDoc(doc(db, "Enquiry", id));
            setEnquiry(enquiry.filter(blog => blog.id !== id));
            console.log("Deleted blog with ID:", id);
            alert("Enquiry Deleted")
        } catch (error) {
            console.error("Error deleting blog:", error);
        }
      }

      useEffect(()=>{
        async function fetchData(){
          const data = await fetchDataFromFirestore()
          setEnquiry(data);
          console.log(data)
        }
        fetchData()
      },[])


    return (
       
       <div>
        <div className='my-8 m-2 '>
        <h1 className=' px-6 py-3 border rounded-2xl text-center text-m font-bold text-gray-400 bg-gray-50 dark:bg-gray-700 dark:text-white'>List of All users Registered on your website</h1>
        </div>
       <div className="m-2 relative overflow-x-auto shadow-md sm:rounded-lg">
           
           
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            User's name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Mobile Number
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Email ID
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Enquiry for 
                        </th>
                        
                        <th scope="col" className="px-6 py-3">
                            <span className="sr-only">Edit</span>
                        </th>
                    </tr>
                </thead>
               
               {
                enquiry.map((item)=>(
                    <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {item.Firstname}  {item.Lastname}
                        </td>
                        <td className="px-6 py-4">
                        {item.Phone}
                        </td>
                        <td className="px-6 py-4">
                        {item.Email}
                        </td>

                        <td className="px-6 py-4">
                        {item.InvestmentPlan}
                        </td>
                        
                        <td className="px-6 py-4 text-right">
                        <button onClick={() => handleDelete(item.id)} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Delete</button>
                        </td>
                    </tr>
                </tbody>
                ))
               }
               
            </table>
        </div>
        </div>
    );
};

export default EnquiryTable;
