import {React, useState} from 'react'
import { db } from '../Firebase/Firebase';
import { collection, addDoc } from "firebase/firestore";
import { useParams } from 'react-router-dom';

const AdminApprovePayment = () => {

    const [selectedOption, setSelectedOption] = useState('');
    const [investmentAmount, setInvestmentAmount] = useState('');
    const [years, setYears] = useState('');
    const [maturiyAmount, setMatuarityAmount] = useState('');
    const [maturityDate, setMaturitDate] = useState('');
    const [payoutFrequency, setPayoutFrequency] = useState('');
    const [UPIID, setUPIID] = useState('');
    const UserID = useParams()
    console.log(UserID)

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
      };

      const finalStep = async (e)=>{
        e.preventDefault();
        console.log(investmentAmount, years, payoutFrequency, UPIID)
        const docRef = await addDoc(collection(db, "Approved-plan"), {
            InvestmentFrequency:selectedOption,
            InvestedAmount:investmentAmount,
            NumberOfYears:years,
            PayoutFrequencyOfUser:payoutFrequency,
            UserUpiID:UPIID,
            USERID: UserID.id
        });
  
      }

  return (
    
      <div>
    <div className="isolate  bg-white px-6 py-10 sm:py-32 lg:px-8">
      <div className="absolute inset-x-0 top-[-10rem] sm:top-[-20rem] transform-gpu overflow-hidden blur-3xl">
        <div
          className="relative left-1/2 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Select your Investment plan</h2>
      </div>
      
      <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <label htmlFor="plan" className="block text-sm font-semibold leading-6 text-gray-900">
              Select invested Plan
            </label>
            <div className="relative mt-2.5">
            <select
                id="plan"
                name="plan"
                value={selectedOption}
                onChange={handleOptionChange}
                required
                className="w-full rounded-md border-100 bg-transparent bg-none py-2 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
              >
                <option value="" disabled>Select your option</option>
                <option className="yearly">SAM Goal Achiever (Yearly)</option>
                <option className="SIP">SAM SIP Goal (Monthly)</option>
            </select>
        </div>
          </div>

        

          {selectedOption === 'SAM Goal Achiever (Yearly)' && (
            <>
              <div>
                <label htmlFor="yearly-investment-amount" className="block text-sm font-semibold leading-6 text-gray-900">
                  Enter  Yearly investmented Amount
                </label>
                <div className="mt-2.5">
                  <input
                    type="number"
                    required
                    onChange={(e) => setInvestmentAmount(e.target.value)} 
                    placeholder="Example: 1,00,000/-"
                    name="investment-amount"
                    id="yearly-investment-amount"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="yearly-duration" className="block text-sm font-semibold leading-6 text-gray-900">
                  Investement Term
                </label>
                <div className="mt-2.5">
                  <select
                    id="yearly-duration"
                    name="yearly-duration"
                    required
                    onChange={(e) => setYears(e.target.value)} 
                    className="w-full rounded-md border-100 bg-transparent bg-none py-2 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                  >
                    <option value="" disabled>Select Years</option>
                    <option>5 Years</option>
                    <option>6 Years</option>
                    <option>10 Years</option>
                    <option>20 Years</option>
                    <option>25 Years</option>
                  </select>
                </div>
              </div>

              <div className="sm:col-span-2">
            <label htmlFor="yearly-interest-payout" className="block text-sm font-semibold leading-6 text-gray-900">
              Choose yearly interest payout
            </label>
            <div className="relative mt-2.5">
              <select
                id="yearly-interest-payout"
                name="yearly-interest-payout"
                required
                onChange={(e) => setPayoutFrequency(e.target.value)} 
                className="w-full rounded-md border-100 bg-transparent bg-none py-2 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
              >
                <option value="" disabled>Select Payout Frequency</option>
                <option>Yearly Payout</option>
                <option>Half Yearly Payout</option>
                <option>Quarterly Payout</option>
                <option>Monthly Payout</option>
              </select>
            </div>
          </div>
            </>
          )}

          {selectedOption === 'SAM SIP Goal (Monthly)' && (
            
            <>
            <div>
              <label htmlFor="sip-investment-amount" className="block text-sm font-semibold leading-6 text-gray-900">
            Invested Monthly SIP Amount
              </label>
              <div className="mt-2.5">
                <input
                  type="number"
                  required
                  onChange={(e) => setInvestmentAmount(e.target.value)} 
                  placeholder="Example: 10,000/-"
                  name="sip-investment-amount"
                  id="sip-investment-amount"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
                <label htmlFor="yearly-duration" className="block text-sm font-semibold leading-6 text-gray-900">
                Investment  Plans Term
                </label>
                <div className="mt-2.5">
                  <select
                    id="yearly-duration"
                    name="yearly-duration"
                    required
                    onChange={(e) => setYears(e.target.value)} 
                    className="w-full rounded-md border-100 bg-transparent bg-none py-2 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                  >
                    <option value="" disabled>Select Years</option>
                    <option>1 Year</option>
                    <option>3 Years</option>
                    <option>5 Years</option>
                  </select>
                </div>
              </div>            
            </>
          )}

<div>
                <label htmlFor="yearly-investment-amount" className="block text-sm font-semibold leading-6 text-gray-900">
                  Enter Maturity Amount
                </label>
                <div className="mt-2.5">
                  <input
                    type="number"
                    required
                    onChange={(e) => setMatuarityAmount(e.target.value)} 
                    placeholder="Example: 1,00,000/-"
                    name="investment-amount"
                    id="yearly-investment-amount"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="yearly-duration" className="block text-sm font-semibold leading-6 text-gray-900">
                  Maturity Date
                </label>
                <div className="mt-2.5">
                <input
                    type="date"
                    required
                    onChange={(e) => setMaturitDate(e.target.value)} 
                    placeholder="Example: 1,00,000/-"
                    name="investment-amount"
                    id="yearly-investment-amount"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

          <div>
            <label htmlFor="upi-id" className="block text-sm font-semibold leading-6 text-gray-900">
              Clients UPI ID to receive your payouts
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                required
                onChange={(e) => setUPIID(e.target.value)} 
                placeholder="Example: yourname@upi"
                name="upi-id"
                id="upi-id"
                className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>

        <div className="mt-10 flex">
          <button
            type="submit"
            className="block w-full m-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={finalStep}
          >
            Approve Payment
          </button>
        </div>
      </form>
    </div>
                    </div>
    
  )
}

export default AdminApprovePayment
