import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; 
import { getStorage} from "firebase/storage";// Import necessary Firestore functions

const firebaseConfig = {
  apiKey: "AIzaSyA0wkoyA2C0aekt-RghxjTcnAg04VKCrUY",
  authDomain: "assetmanagement-732fd.firebaseapp.com",
  projectId: "assetmanagement-732fd",
  storageBucket: "assetmanagement-732fd.appspot.com",
  messagingSenderId: "727921253956",
  appId: "1:727921253956:web:3e6522c31b8f5aed3d2611",
  measurementId: "G-3YM84NTW9N"
}; 

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app); // Initialize the Realtime Database
const storage = getStorage(app);

export { app, auth, db, storage };
