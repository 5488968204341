import React, { useState, useEffect } from 'react';
import { getStorage } from "firebase/storage";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../Firebase/Firebase';
import { useParams, Link } from 'react-router-dom';

const UpdateBlog = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [activeComponent, setActiveComponent] = useState(null);
    const [heading, setHeading] = useState("");
    const [subHeading, setSubHeading] = useState("");
    const [content, setContent] = useState("");
    const [blogImage, setBlogImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const blogId = params.id;

   

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const closeSidebar = () => {
        setIsOpen(false);
    };

    const handleComponentChange = (componentName) => {
        setActiveComponent(componentName);
    };



    useEffect(() => {
        // Fetch the existing blog post data
        async function fetchBlogData() {
            try {
                setLoading(true);
                const docRef = doc(db, "Blogs", blogId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setHeading(data.Heading);
                    setSubHeading(data.SubHeading);
                    setContent(data.Content);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error getting document:", error);
            } finally {
                setLoading(false);
            }
        }
        fetchBlogData();
    }, [blogId]);

    const handlefinalStep = async (e) => {
        e.preventDefault();
        try {
            const docRef = doc(db, "Blogs", blogId);
            await updateDoc(docRef, {
                Heading: heading,
                SubHeading: subHeading,
                Content: content,
                // You may need to update the image handling logic here
            });
            alert("Blog Post Updated Successfully");
            // Optionally, redirect the user to the updated blog post page or any other page
        } catch (error) {
            console.error("Error updating blog post:", error);
            alert("Failed to update blog post. Please try again.");
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }


  return (
<>

<>
            <button
                onClick={toggleSidebar}
                type="button"
                className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
                <span className="sr-only">{isOpen ? 'Close sidebar' : 'Open sidebar'}</span>
                <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    {isOpen ? (
                        <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M4.75 2A.75.75 0 014 2.75v14.5a.75.75 0 011.5 0V2.75A.75.75 0 014.75 2zm10.5 0a.75.75 0 00-.75.75v14.5a.75.75 0 001.5 0V2.75a.75.75 0 00-.75-.75zM10 2a.75.75 0 00-.75.75v14.5a.75.75 0 001.5 0V2.75A.75.75 0 0010 2z"
                        ></path>
                    ) : (
                        <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5h-14.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                        ></path>
                    )}
                </svg>
            </button>
            <aside
                id="sidebar-multi-level-sidebar"
                className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
                    isOpen ? '' : '-translate-x-full sm:translate-x-0'
                }`}
                aria-label="Sidebar"
            >
                <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
                    <button
                        onClick={closeSidebar}
                        type="button"
                        className="absolute top-0 right-0 m-4 text-gray-500 sm:hidden"
                    >
                        <span className="sr-only">Close sidebar</span>
                        <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.178 5.86a.75.75 0 01.885 1.21l-7.071 8a.75.75 0 11-1.167-.943l7.071-8a.75.75 0 01.282-.267z"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.107 5.86a.75.75 0 10-1.167.943l7.071 8a.75.75 0 001.167-.943l-7.071-8a.75.75 0 00-.282-.267z"
                            />
                        </svg>
                    </button>
                    <ul className="space-y-2 font-medium">
                        <li>
                            <Link
                            to={"/admin/83547-245"}
                                href="/admin"
                                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            >
                                <svg
                                    className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 22 21"
                                >
                                    <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                                    <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                                </svg>
                                <span className="ms-3">Dashboard</span>
                            </Link>
                        </li>
                        <li>
            <Link onClick={() => handleComponentChange('Users')} to={"/admin/83547-245"} href="/admin" class="flex items-center p-2 mt-5 pt-20 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                  <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
               </svg>
               <span class="flex-1 ms-3 whitespace-nowrap">Users</span>
            </Link>
         </li>

         <li>
            <Link onClick={() => handleComponentChange('CreateBlog')} to={'/admin/83547-245'} href='/admin' class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                  <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
               </svg>
               <span class="flex-1 ms-3 whitespace-nowrap">Create Blogs</span>
            </Link>
         </li>

         <li>
            <Link onClick={() => handleComponentChange('ManageBlogPost')} to={"/admin/83547-245"} href="/admin" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                  <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
               </svg>
               <span class="flex-1 ms-3 whitespace-nowrap">Manage Blogs</span>
            </Link>
         </li>
                    </ul>
                </div>
            </aside>
            <div className="p-4 sm:ml-64">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                    <h1 className='text-center text-3xl font-bold'>Hello Admin</h1>
                </div>


                <div className='bg'>

        <h1 className='text-center font-bold text-3xl my-8 w-full bg-[#374251] text-gray-300 border rounded-3xl p-3'>Update your Blog post</h1>
      <form className=" w-[70vw] mx-auto bg">


        <div className="mb-5">
                <label htmlFor="base-input" className="block mb-2 text-m font-bold font-medium text-gray-900 dark:text-gray-900">Blog Heading</label>
                <input onChange={(e) => setHeading(e.target.value)}  placeholder='heading' type="text" id="base-input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
        </div>

        <div className="mb-5">
                <label htmlFor="base-input" className="block mb-2 text-m font-medium text-gray-900 dark:text-gray-900">Blog Sub-Heading</label>
                <input onChange={(e) => setSubHeading(e.target.value)}  placeholder='Sub heading' type="text" id="base-input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
        </div>

        <div className="mb-5">
                <label htmlFor="base-input" className="block mb-2 text-m font-medium text-gray-900 dark:text-gray-900">Blog Sub-Heading</label>
                <textarea onChange={(e) => setContent(e.target.value)}  id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Content"></textarea>
        </div>

        <div>
        <label htmlFor="base-input" className="block mb-2 text-m font-medium text-gray-900 dark:text-gray-900">Upload image</label>
                <input onChange={(e) => setBlogImage(e.target.files[0])} class="block p-2.5 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file"/>
        </div>

        <button onClick={handlefinalStep} class="text-white mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Update Blog Post </button>
      </form>
    </div>
                
            </div>
        </>









</>   
  )
}

export default UpdateBlog
