import './App.css';
import EnquiryForm from './components/EnquiryForm';

import {
  Route,
  Routes, 
} from "react-router-dom";
import MainHomePage from './components/MainHomePage';
import SignUpPage from './components/SignUP';
import Blog from './components/Blog';
import InvestmentPlans from './components/InvestmentPlans';
import Calculator from './components/Calculator';
import CheckOutForm from './components/CheckOutForm';
import LoginPage from './components/Login';
import Sidebar from './Admin/Sidebar';
import UserData from './Admin/UserData';
import UserSidebar from './components/Sidebar';
import UpdateBlog from './Admin/UpdateBlog'
import AdminApprovePayment from './Admin/AdminApprovePayment';
import ForgotPassword from './components/ForgotPassword';
import AboutUs from './components/aboutUs/Aboutus';
import KYC from './components/KYC';
import InsuranceEnquiry from './components/InsuranceEnquiry';
import CreditCardEnquiry from './components/CreditCardEnquiry';
import UpdateUsersPayment from './Admin/UpdateUsersPayment';
// import AdminPage from './Admin/AdminPage';


function App() {
  
  return (
    <>


<div>
<Routes>
<Route path='' element={<MainHomePage/>}></Route>
<Route path='/enquiry' element={<EnquiryForm/>}></Route>
<Route path='/signup' element={<SignUpPage/>}></Route>
<Route path='/blog/:id' element={<Blog/>}></Route>
<Route path='/plans' element={<InvestmentPlans/>}></Route>
<Route path='/calculators' element={<Calculator/>}></Route>
<Route path='/checkout' element={<CheckOutForm/>}></Route>
<Route path='/login' element={<LoginPage/>}></Route>
<Route path='/user/dashboard' element={<UserSidebar/>}></Route>
<Route path='/admin/83547-245' element={<Sidebar/>}></Route>
<Route path='/admin/83547-245/userdata/:id' element={<UserData/>} ></Route>
<Route path='/admin/83547-245/userdata/:id/:id' element={<AdminApprovePayment/>} ></Route>
<Route path='/admin/83547-245/updateBlog/:id' element={<UpdateBlog/>} ></Route>
<Route path='/forgotPassword' element={<ForgotPassword/>} ></Route>
<Route path='/aboutUs' element={<AboutUs/>} ></Route>
<Route path='/KYC' element={<KYC/>} ></Route>
<Route path='/insuranceEnquiry' element={<InsuranceEnquiry/>} ></Route>
<Route path='/apply/creditCard' element={<CreditCardEnquiry/>} ></Route>
<Route path='//admin/updateUserPayment/:id' element={<UpdateUsersPayment/>} ></Route>
</Routes>
</div>

</>
  );
}

export default App;
